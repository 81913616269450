import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

export const env = createEnv({
  server: {
    NEXTAUTH_SECRET: z.string(),
    NEXTAUTH_URL: z.string().url().optional(),
    AUTH0_CLIENT_ID: z.string(),
    AUTH0_CLIENT_SECRET: z.string(),
    AUTH0_ISSUER: z.string(),
    AUTH0_AUDIENCE: z.string(),
    BITS_API_URL: z.string(),
    VERCEL_URL: z.string().optional(),
    PORT: z
      .preprocess(
        (str) => (str ? parseInt(str, 10) : undefined),
        z.number().int().positive()
      )
      .optional(),
  },
  client: {
    NEXT_PUBLIC_VERCEL_URL: z.string().optional(),
    NEXT_PUBLIC_VERCEL_ENV: z
      .union([
        z.literal('production'),
        z.literal('preview'),
        z.literal('development'),
      ])
      .optional(),
    NEXT_PUBLIC_DATADOG_APPLICATION_ID: z.string().optional(),
    NEXT_PUBLIC_DATADOG_CLIENT_TOKEN: z.string().optional(),
    NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA: z.string().optional(),
    NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF: z.string().optional(),
  },
  runtimeEnv: {
    NEXTAUTH_SECRET: process.env['NEXTAUTH_SECRET'],
    NEXTAUTH_URL: process.env['NEXTAUTH_URL'],
    AUTH0_CLIENT_ID: process.env['AUTH0_CLIENT_ID'],
    AUTH0_CLIENT_SECRET: process.env['AUTH0_CLIENT_SECRET'],
    AUTH0_ISSUER: process.env['AUTH0_ISSUER'],
    AUTH0_AUDIENCE: process.env['AUTH0_AUDIENCE'],
    BITS_API_URL: process.env['BITS_API_URL'],
    PORT: process.env['PORT'],
    VERCEL_URL: process.env['VERCEL_URL'],

    // Client ENVS
    NEXT_PUBLIC_VERCEL_ENV: process.env['NEXT_PUBLIC_VERCEL_ENV'],
    NEXT_PUBLIC_VERCEL_URL: process.env['NEXT_PUBLIC_VERCEL_URL'],
    NEXT_PUBLIC_DATADOG_APPLICATION_ID:
      process.env['NEXT_PUBLIC_DATADOG_APPLICATION_ID'],
    NEXT_PUBLIC_DATADOG_CLIENT_TOKEN:
      process.env['NEXT_PUBLIC_DATADOG_CLIENT_TOKEN'],
    NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA:
      process.env['NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA'],
    NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF:
      process.env['NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF'],
  },
  skipValidation: !!process.env.SKIP_ENV_VALIDATION,
});
