import React, { useMemo } from 'react';
import { CircleFlag, ISO3166Alpha2 } from '@bits/i18n';
import clsx from 'clsx';

import { cn } from '../utils/cn';
import { countryCodeToName } from '../utils/country-code';
import { ThemedSearchableSelect } from './themed-searchable-select';

export const ThemedCountrySelect = ({
  label,
  values,
  emptyText,
  placeholder,
  multiselect,
  language,
  error,
  onValuesChange,
  hideValueLabelOnSmall = false,
  disabled,
}: {
  label?: string;
  values?: ISO3166Alpha2[];
  emptyText: string;
  placeholder: string;
  multiselect?: boolean;
  language: string;
  error?: string;
  onValuesChange: (values: ISO3166Alpha2[]) => void;
  hideValueLabelOnSmall?: boolean;
  disabled?: boolean;
}) => {
  const countryOptions = useMemo(
    () =>
      ISO3166Alpha2.map((countryCode) => ({
        label: countryCodeToName(countryCode, language) || '',
        value: countryCode,
        data: {
          name: countryCodeToName(countryCode, language) || '',
        },
      })).sort((a, b) => a.data.name.localeCompare(b.data.name)),
    [language]
  );

  return (
    <label>
      {label && (
        <p
          className={cn(
            'text-theme-inputs-labelFontSize text-theme-inputs-labelTextColor mb-2',
            error && 'text-theme-inputs-errorColor'
          )}
        >
          {label}
        </p>
      )}
      <ThemedSearchableSelect
        values={values}
        emptyText={emptyText}
        placeholder={placeholder}
        error={error}
        onValuesChange={(val) => onValuesChange(val as ISO3166Alpha2[])}
        multiselect={multiselect}
        options={countryOptions}
        disabled={disabled}
        renderOption={(option) => (
          <div className="flex items-center gap-2 overflow-hidden">
            <div className="bg-theme-typography-paragraph-textColor/20 text-theme-inputs-fontSize size-5 shrink-0 overflow-hidden rounded-full">
              <CircleFlag countryCode={option.value as string} />
            </div>
            <span className="truncate">{option.label}</span>
          </div>
        )}
        renderValues={(values) => {
          return (
            <div
              className={clsx(
                'text-theme-inputs-fontSize flex max-w-full items-center gap-2'
              )}
            >
              {!multiselect && (
                <div className="size-5 shrink-0 overflow-hidden rounded-full">
                  <CircleFlag countryCode={values[0] as string} />
                </div>
              )}
              <span
                className={clsx(
                  'w-full truncate',
                  hideValueLabelOnSmall && 'hidden sm:block'
                )}
              >
                {values
                  ?.map((value) => countryCodeToName(value, language))
                  .join(', ')}
              </span>
            </div>
          );
        }}
      />
    </label>
  );
};
