const defaultErrorMessages = {
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  422: 'Unprocessable Entity',
  500: 'Internal Server Error',
};

export class HttpError extends Error {
  public json?: object;

  constructor(
    public statusCode: number = 500,
    public message: string = defaultErrorMessages[
      statusCode as keyof typeof defaultErrorMessages
    ] || defaultErrorMessages[500],
    public text?: string,
    public correlationId?: string
  ) {
    super(message);
    this.name = 'HttpError';

    const json = safeParseJson(text);
    this.json = json;
  }
}

const safeParseJson = (text?: string) => {
  try {
    return JSON.parse(text || '');
  } catch (err) {
    return undefined;
  }
};
