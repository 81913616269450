import { CSSProperties } from 'react';
import bitsColors from '@bits/tailwind-config/bits-colors';

import { cn } from './utils/cn';

export const RatingButtons = ({
  max = 4,
  min = 1,
  onValueChange,
  value,
  colors = [
    bitsColors['kiwi-light'],
    bitsColors['khaki'],
    bitsColors['tangerine-light'],
    bitsColors['grapefruit'],
  ],
}: {
  max?: number;
  min?: number;
  onValueChange: (rating: number | null) => void;
  value: number | undefined | null;
  colors?: string[];
}) => {
  return (
    <div className="flex w-full gap-4">
      {Array.from({ length: max - min + 1 }, (_, i) => i + min).map(
        (rating) => (
          <button
            type="button"
            style={
              {
                '--color': colors?.[rating - 1],
              } as CSSProperties
            }
            className={cn(
              'bits-text-button-1 w-full rounded-md px-4 h-10 items-center justify-center text-ink border',
              'transition-all duration-50',
              value !== rating &&
                'hover:bg-[color-mix(in_srgb,var(--color),transparent)]',
              value === rating
                ? 'bg-[var(--color)] border-[var(--color)]'
                : 'bg-white'
            )}
            key={rating}
            onClick={() => {
              if (value === rating) {
                onValueChange(null);
                return;
              }
              onValueChange(rating);
            }}
            aria-pressed={value === rating}
          >
            {rating}
          </button>
        )
      )}
    </div>
  );
};
