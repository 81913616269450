import {
  CircleFlag as CircleFlag_,
  CircleFlagLanguage,
} from 'react-circle-flags';

export const CircleFlag = ({
  countryCode = '',
  className,
}: {
  countryCode: string;
  className?: string;
}) => (
  <CircleFlag_
    countryCode={countryCode.toLowerCase()}
    className={className}
    aria-hidden
  />
);

export const CircleLanguageFlag = ({
  languageCode = '',
  className,
}: {
  languageCode: string;
  className?: string;
}) => (
  <CircleFlagLanguage
    languageCode={languageCode.toLowerCase()}
    className={className}
    aria-hidden
  />
);
