import React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { VariantProps } from 'class-variance-authority';

import { ButtonProps, buttonVariants } from './base-button';
import { cn } from './utils/cn';

const buttonClass = 'rounded-full bits-text-button-2 gap-1 h-8 px-4 py-2';

export const smallButtonVariants = (
  variant: VariantProps<typeof buttonVariants>['variant']
) => {
  return cn(buttonClass, buttonVariants({ variant }));
};

export interface SmallButtonProps extends ButtonProps {
  iconGravity?: 'left' | 'right';
}

export const SmallButton = React.forwardRef<
  HTMLButtonElement,
  SmallButtonProps
>(
  (
    {
      asChild,
      children,
      variant,
      className,
      iconGravity = 'left',
      icon: Icon,
      iconProps,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(
          buttonClass,
          buttonVariants({ variant, className }),
          iconGravity === 'right' ? 'flex-row-reverse' : undefined
        )}
        ref={ref}
        {...props}
      >
        {Icon ? (
          <Icon
            {...iconProps}
            className={cn('h-4 w-4', iconProps?.className)}
          />
        ) : null}
        {children}
      </Comp>
    );
  }
);

SmallButton.displayName = 'SmallButton';
