const finnishCenturyMap: { [key: string]: number } = {
  F: 2000,
  E: 2000,
  D: 2000,
  C: 2000,
  B: 2000,
  A: 2000,
  U: 1900,
  V: 1900,
  W: 1900,
  X: 1900,
  Y: 1900,
  '-': 1900,
  '+': 1800,
};
const extractHETU = (
  id: string
): { checksumBase: number; controlChar: string; valid: boolean } => {
  const dob = id.substring(0, 6);
  const midChar = id.substring(6, 7);
  const individualisedString = id.substring(7, 10);
  const controlChar = id.substring(10, id.length);

  const day = parseInt(dob.substring(0, 2), 10);
  if (day < 1 || day > 31) {
    return { valid: false, checksumBase: 0, controlChar: '' };
  }
  const month = parseInt(dob.substring(2, 4));
  if (month < 1 || month > 12) {
    return { valid: false, checksumBase: 0, controlChar: '' };
  }
  const year =
    parseInt(dob.substring(4, 6)) + (finnishCenturyMap[midChar] || 0);

  const inMonth = new Date(year, month, 0).getDate();
  if (inMonth == 0 || day > inMonth) {
    return { valid: false, checksumBase: 0, controlChar: '' };
  }

  const individualisedNum = parseInt(individualisedString);
  if (individualisedNum < 0 || individualisedNum > 999) {
    return { valid: false, checksumBase: 0, controlChar: '' };
  }

  const checksumBase = parseInt(dob + individualisedString);

  return { checksumBase, controlChar, valid: true };
};
const validateFinnishChecksum = (checksumBase: number, controlChar: string) => {
  const checksum = checksumBase % 31;
  const controlCharIndex = '0123456789ABCDEFHJKLMNPRSTUVWXY'.indexOf(
    controlChar
  );
  return checksum === controlCharIndex;
};
export const validateFinnishPersonalIdentityNumber = (id: string) => {
  // validate Finnish HETU number
  const matchHETU =
    /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])([5-9]\d\+|\d\d[-U-Y]|[012]\d[A-F])\d{3}[\dA-Z]$/.test(
      id
    );
  if (matchHETU) {
    const { checksumBase, controlChar, valid } = extractHETU(id);
    if (valid) {
      return validateFinnishChecksum(checksumBase, controlChar);
    }
  }

  const matchSATU = /^(\d{8}[\dA-Z])$/.test(id);

  if (matchSATU) {
    const checksumBase = parseInt(id.substring(0, 8));
    const controlChar = id.substring(8, id.length);
    return validateFinnishChecksum(checksumBase, controlChar);
  }

  return false;
};
