import { fetcher } from '@bits/fetcher';
import { SWRConfiguration } from 'swr';
import useSWRImmutable from 'swr/immutable';

import { apiRoutes } from '../api/routes';
import { meSchema } from '../validations/api/me';

export function useCurrentUser(key?: string, opts?: SWRConfiguration) {
  const data = useSWRImmutable(
    [apiRoutes.me, key],
    ([url]) => fetcher(meSchema)(url),
    opts
  );
  const isBitsEmployee = data.data?.email.endsWith('@bits.bi');

  return {
    ...data,
    email: data.data?.email,
    isBitsEmployee,
  };
}
