export const formatFileSize = (size?: number | null) => {
  if (size == null) {
    return '0 Bytes';
  }
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let i = 0;
  while (size >= 1024 && i < units.length - 1) {
    size /= 1024;
    i++;
  }
  return `${size.toFixed(2).replace('.00', '')} ${units[i]}`;
};
