import { ReactNode } from 'react';
import { formatDate } from '@bits/i18n';

import { AdvancedFilterGroup, AdvancedFilterObject } from './advanced-filters';

export const formatActiveFilterLabel = (
  filter: AdvancedFilterGroup,
  values: AdvancedFilterObject[string]
): (string | number | ReactNode)[] => {
  // Option style filter
  if (filter.type === 'options') {
    return values
      .filter((v) => !!v)
      .map(
        (v) =>
          filter?.options.find(({ value }) => value === v)?.label ||
          (v as string)
      );
  }

  // Date range type
  const from = values[0] ? formatDate(values[0]) : '';
  const to = values[1] ? formatDate(values[1]) : '';
  return [
    ...[from && !to && `from ${from}`],
    ...[!from && to && `before ${to}`],
    ...[from && to && `from ${from} until ${to}`],
  ].filter(Boolean);
};

export const labelFromSavedFilter = (
  savedFilter: AdvancedFilterObject,
  filters: AdvancedFilterGroup[]
) => {
  return Object.entries(savedFilter).map(([key, values], index) => {
    const filter = filters.find((f) => f.id === key);
    if (!filter) {
      return '';
    }

    const labels = formatActiveFilterLabel(filter, values);

    return (
      <>
        {index > 0 && ', '}
        {labels.map((label, i) => (
          <>
            {i > 0 && ', '}
            {label}
          </>
        ))}
      </>
    );
  });
};
