import { useEffect, useState } from 'react';
import { ISO3166Alpha2 } from '@bits/i18n';

import { cn } from '../utils/cn';
import { useUuid } from '../utils/use-uuid';
import { ThemedCountrySelect } from './themed-country-select';
import { ThemedInput } from './themed-input';

export type ThemedCountrySpecificStringInputProps = {
  label?: string;
  error?: string;
  emptyText: string;
  placeholder: string;
  language: string;
  value?: { id: string; issuingCountry: ISO3166Alpha2 } | null;
  defaultCountryCode?: ISO3166Alpha2;
  formatText?: string | null;
  onValueChange?: (value: {
    issuingCountry: ISO3166Alpha2 | null;
    id: string;
  }) => void;
  getStringFormat: (
    country: ISO3166Alpha2
  ) => { format: string; example: string } | undefined;
};

export const ThemedCountrySpecificStringInput = ({
  label,
  error,
  emptyText,
  placeholder,
  language,
  value,
  defaultCountryCode,
  formatText,
  onValueChange,
  getStringFormat,
}: ThemedCountrySpecificStringInputProps) => {
  const hintId = useUuid();
  const errorId = useUuid();
  const [issuingCountry, setIssuingCountry] = useState<ISO3166Alpha2>(
    value?.issuingCountry ?? (defaultCountryCode as ISO3166Alpha2)
  );
  const [id, setId] = useState(value?.id ?? '');

  const numberFormat = getStringFormat(issuingCountry);
  const hint = numberFormat
    ? formatText?.replace('{{value}}', numberFormat.format)
    : '';

  useEffect(() => {
    onValueChange?.({ issuingCountry, id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, issuingCountry]);

  const example = getStringFormat(issuingCountry);
  return (
    <div className="grid gap-1">
      <p
        className={cn(
          'text-theme-inputs-labelFontSize text-theme-inputs-labelTextColor mb-2',
          error && 'text-theme-inputs-errorColor'
        )}
      >
        {label}
      </p>
      <div className="flex gap-2">
        <div className="max-w-40 shrink-0 overflow-hidden">
          <ThemedCountrySelect
            values={[issuingCountry]}
            emptyText={emptyText}
            placeholder={placeholder}
            onValuesChange={([v = '']) => {
              setIssuingCountry(v as ISO3166Alpha2);
            }}
            language={language as ISO3166Alpha2}
            hideValueLabelOnSmall
          />
        </div>

        <div className="w-full">
          <ThemedInput
            type="text"
            onValueChange={setId}
            value={id}
            error={!!error}
            aria-label={label}
            aria-describedby={error ? errorId : hintId}
            placeholder={example?.example}
          />
        </div>
      </div>
      {hint && (
        <p
          id={hintId}
          className={cn(
            'text-theme-inputs-hintTextColor text-theme-inputs-hintAndErrorFontSize'
          )}
        >
          {hint}
        </p>
      )}
      {error && (
        <p
          id={errorId}
          className={cn(
            'text-theme-inputs-labelTextColor text-theme-inputs-hintAndErrorFontSize',
            'text-theme-inputs-errorColor text-theme-inputs-hintAndErrorFontSize'
          )}
        >
          {error}
        </p>
      )}
    </div>
  );
};
