import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';

import { cn } from './utils/cn';

export const VirtualizedList = <Row,>({
  rowHeight = 40,
  padding = 100,
  rows,
  renderRow,
  className,
}: {
  rowHeight: number;
  rows: Row[];
  padding?: number;
  renderRow: (options: {
    row: Row;
    style: CSSProperties;
    index: number;
  }) => ReactNode;
  className?: string;
}) => {
  const listRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState({ start: 0, end: 10 });

  useEffect(() => {
    const container = listRef.current;

    const handleScroll = () => {
      if (!container) return;
      const bounds = container.getBoundingClientRect();

      const start = (container.scrollTop - padding) / rowHeight;

      const end = Math.min(
        rows.length,
        Math.ceil((container.scrollTop + padding + bounds.height) / rowHeight)
      );

      setVisible({ start, end });
    };

    setTimeout(() => handleScroll());

    container?.addEventListener('scroll', handleScroll);
    window?.addEventListener('resize', handleScroll);

    return () => {
      container?.removeEventListener('scroll', handleScroll);
      window?.removeEventListener('resize', handleScroll);
    };
  }, [rows, padding, rowHeight]);

  return (
    <div ref={listRef} className={cn('overflow-auto relative', className)}>
      <ul style={{ height: rows.length * rowHeight, position: 'relative' }}>
        {rows.map((row, index) =>
          index >= visible.start && index <= visible.end
            ? renderRow({
                style: {
                  position: 'absolute',
                  top: index * rowHeight,
                },
                row,
                index,
              })
            : null
        )}
      </ul>
    </div>
  );
};
