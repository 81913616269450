import React, { ChangeEvent, forwardRef, useCallback } from 'react';

import { Input, InputProps } from '../input';
import { Label } from '../label';
import { InputErrorAndHint } from './input-error-and-hint';

interface TextInputProps extends InputProps {
  label?: string;
  error?: string;
  hint?: string;
  onValueChange?: (value: string) => void;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ label, hint, error, onValueChange, ...props }: TextInputProps, ref) => {
    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        props.onChange?.(event);
        onValueChange?.(event.target.value);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.onChange, onValueChange]
    );

    return (
      <div>
        <Label>
          {label && <p className="bits-text-body-2 mb-1">{label}</p>}
          <Input {...props} ref={ref} onChange={handleChange} />
        </Label>
        <InputErrorAndHint error={error} hint={hint} />
      </div>
    );
  }
);

TextInput.displayName = 'TextInput';
