import { ISO3166Alpha2, ISO3166Alpha2OrUnknown } from '../iso-3166';

const personalIdentityNumberFormats: Partial<
  Record<ISO3166Alpha2, { format: string; example: string }>
> = {
  SE: {
    format: 'YYYYMMDD-XXXX',
    example: '19700101-1234',
  },
  FI: {
    format: 'DDMMYY-XXXX',
    example: '010170-1234',
  },
  DK: {
    format: 'DDMMYY-XXXX',
    example: '010170-1234',
  },
};

export const getPersonalIdentityNumberFormat = (
  market: ISO3166Alpha2
): { format: string; example: string } | undefined => {
  return personalIdentityNumberFormats[market];
};

export const formatPersonalIdentityNumber = (
  value?: string,
  market?: ISO3166Alpha2OrUnknown
) => {
  if (!value) {
    return '';
  }

  if (!market) {
    return value;
  }

  // Finland and Sweden
  if (['FI', 'SE'].includes(market)) {
    return (
      value
        // Replace all non digits and letters
        .replace(/[^0-9a-zA-Z]/g, '')
        // Add a dash after the sixth, or eigth, character
        .replace(/^(\d{6}|\d{8})(.{4})$/, '$1-$2')
    );
  }

  // Norway
  if (market === 'NO') {
    return (
      value
        // Replace all non digits and letters
        .replace(/[^0-9a-zA-Z]/g, '')
        // Add a dash after the sixth character
        .replace(/(\d{6})(.{5})/, '$1 $2')
    );
  }

  return value;
};
