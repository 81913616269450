import React, { ComponentPropsWithoutRef, useMemo } from 'react';
import {
  CustomerThemeInputSchema,
  objectToCustomerTheme,
} from '@bits/customer-theme';
import { Slot } from '@radix-ui/react-slot';

import { cn } from '../utils/cn';

export const CustomerThemeProvider = ({
  theme = {},
  asChild,
  className,
  ...props
}: ComponentPropsWithoutRef<'div'> & {
  theme?: CustomerThemeInputSchema;
  asChild?: boolean;
}) => {
  const { cssVariables, customerTheme } = useMemo(
    () => objectToCustomerTheme(theme || {}),
    [theme]
  );

  const Comp = asChild ? Slot : 'div';

  return (
    <>
      <style>{`
        ${
          customerTheme.general.fonts.regular
            ? `@font-face { 
                font-family: 'bits-theme-font'; 
                font-weight: normal;
                src: url('${customerTheme.general.fonts.regular.url}'); 
              }`
            : ''
        }
        ${
          customerTheme.general.fonts.bold
            ? `@font-face { 
                font-family: 'bits-theme-font'; 
                font-weight: bold;
                src: url('${customerTheme.general.fonts.bold.url}'); 
              }`
            : ''
        }
      `}</style>
      <Comp
        style={{
          fontFamily: '"bits-theme-font", "Aktiv Grotesk", sans-serif',
          ...cssVariables,
        }}
        className={cn('text-theme-typography-paragraph-textColor', className)}
        {...props}
      />
    </>
  );
};

export const getCustomerThemeRootStyle = (
  theme: CustomerThemeInputSchema = {}
) => {
  const { customerTheme, cssVariables } = objectToCustomerTheme(theme);

  return `
${
  customerTheme.general.fonts.regular
    ? `@font-face { 
        font-family: 'bits-theme-font'; 
        font-weight: normal;
        src: url('${customerTheme.general.fonts.regular.url}'); 
      }`
    : ''
}
${
  customerTheme.general.fonts.bold
    ? `@font-face { 
        font-family: 'bits-theme-font'; 
        font-weight: bold;
        src: url('${customerTheme.general.fonts.bold.url}'); 
      }`
    : ''
}

:root {
  ${Object.entries(cssVariables)
    .map(([key, value]) => {
      return `${key}: ${value};`;
    })
    .join('\n')}
}
`.trim();
};
