export const getBitsEnvironment = () => {
  if (typeof window !== 'undefined') {
    const hostname = window.location.hostname;

    if (hostname === 'localhost' || hostname.includes('.local')) {
      return 'development';
    } else if (hostname.includes('bits.beer')) {
      return 'staging';
    } else if (hostname.includes('bits.builders')) {
      return 'sandbox';
    }
  }

  return 'production';
};
