import React, { forwardRef, ReactNode, useEffect, useState } from 'react';
import { CaretDown } from '@phosphor-icons/react';
import { cva } from 'class-variance-authority';

import { Label } from '../label';
import { InputErrorAndHint } from './input-error-and-hint';

export interface FormSelectProps {
  label?: string;
  error?: string;
  hint?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (e: { target: { name?: string; value: string } }) => void;
  options: {
    value: string;
    label: string;
    disabled?: boolean;
  }[];
  onValueChange?: (value: string) => void;
  variant?: 'default' | 'slim';
  renderValue?: (options: {
    value?: string;
    placeholder?: string;
    options: { value: string; label: string }[];
  }) => ReactNode;
}

const valueVariants = cva(
  [
    'border-fog items-center rounded-lg border p-2 px-3 pr-7',
    'min-w-0 overflow-hidden text-ellipsis text-nowrap',
  ],
  {
    variants: {
      variant: {
        default: 'bits-text-body-1  h-11',
        slim: 'bits-text-button-1 h-9 font-normal leading-4',
      },
    },
  }
);

export const FormSelect = forwardRef<HTMLSelectElement, FormSelectProps>(
  (
    {
      label,
      options,
      error,
      hint,
      value,
      onChange,
      variant = 'default',
      renderValue,
      placeholder,
      onValueChange,
      disabled,
      ...props
    },
    ref
  ) => {
    const [displayValue, setDisplayValue] = useState(value);

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      setDisplayValue(e.target.value);
      onChange?.({
        target: {
          name: e.target.name,
          value: e.target.value,
        },
      });
      onValueChange?.(e.target.value);
    };

    useEffect(() => {
      setDisplayValue(value);
    }, [value]);

    const selectedOption = options.find(
      (option) => option.value === displayValue
    )?.label;

    return (
      <div>
        <Label className={disabled ? 'opacity-50' : ''}>
          {label && <p className="bits-text-body-2 mb-1">{label}</p>}
          <div className="relative bg-white">
            <select
              ref={ref}
              disabled={disabled}
              onChange={handleChange}
              className="absolute left-0 top-0 size-full opacity-0"
              value={displayValue}
              {...props}
            >
              {options.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  disabled={option.disabled}
                >
                  {option.label}
                </option>
              ))}
            </select>
            <div aria-hidden className={valueVariants({ variant })}>
              {renderValue
                ? renderValue({ value: displayValue, options, placeholder })
                : selectedOption || (
                    <span className="text-ash">{placeholder}</span>
                  )}
            </div>
            <CaretDown
              aria-hidden
              className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2"
            />
          </div>
        </Label>
        <InputErrorAndHint error={error} hint={hint} />
      </div>
    );
  }
);

FormSelect.displayName = 'FormSelect';
