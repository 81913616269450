const luhn = (id: string) => {
  let sum = 0;
  for (let i = 0; i < id.length; i++) {
    let d = parseInt(id.substring(i, i + 1));
    if (i % 2 === 0) {
      d *= 2;
      if (d > 9) {
        d -= 9;
      }
    }
    sum += d;
  }
  return sum % 10 === 0;
};
// validateSwedishPersonalIdentityNumber using the luhn algorithm
export const validateSwedishPersonalIdentityNumber = (id: string) => {
  const match = /^(19|20)(\d{6})-?(\d{4})$/.test(id);

  if (!match) {
    return false;
  }

  id = id.replace('-', '');

  if (id.length === 12) {
    id = id.substring(2, id.length);
  }

  return luhn(id);
};
