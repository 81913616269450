import React from 'react';
import { CaretRight } from '@phosphor-icons/react';

import { cn } from './utils/cn';

export interface ExpandableCaretProps
  extends React.HTMLAttributes<HTMLSpanElement> {
  expanded: boolean;
}

export const ExpandableCaret = React.forwardRef<
  HTMLSpanElement,
  ExpandableCaretProps
>(({ className, expanded, ...props }, ref) => {
  return (
    <span
      className={cn(
        'rounded-full size-5 shrink-0 bg-fog text-ink transition-all flex items-center justify-center',
        expanded && 'rotate-90 bg-ink text-white',
        className
      )}
    >
      <CaretRight className="w-3" weight="bold" />
    </span>
  );
});

ExpandableCaret.displayName = 'ExpandableCaret';
