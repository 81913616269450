import { useFlag } from '@openfeature/react-sdk';

import { availableFeatureFlags } from './feature-flags-provider';

export const useEnabledBetaSteps = () => {
  return useFlag<string[]>(
    availableFeatureFlags.ENABLED_BETA_WORKFLOW_STEPS,
    []
  );
};
