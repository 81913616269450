import { RefObject, useEffect, useRef } from 'react';
import { ArrowDown } from '@phosphor-icons/react';
import clsx from 'clsx';

export interface ThemedBelowFoldIndicatorProps<T extends HTMLElement> {
  container: RefObject<T> | undefined;
  selector: string;
  text: string;
  className?: string;
}

/**
 * An indicator for when there are elements below the fold.
 * @param container The container to check for elements below the fold.
 * @param selector The selector to use to find elements below the fold.
 * @param text The text to display in the indicator. Use '{{amount}}' to insert the number of elements below the fold.
 *
 * @example
 * ```tsx
 * const ref = useRef<HTMLDivElement>(null);
 *
 * return (
 *  <div className="relative">
 *   <div className="h-[300px] overflow-auto" ref={ref}>
 *     <h2>Below the fold indicator...</h2>
 *
 *     <ul className="grid gap-3">
 *       {Array.from({ length: 100 }).map((_, i) => (
 *         <li className="h-10 bg-gray-400 rounded-lg" key={i}>
 *           Item {i}
 *         </li>
 *       ))}
 *     </ul>
 *   </div>
 *   <ThemedBelowFoldIndicator
 *     container={ref}
 *     selector="li"
 *     text="{{amount}} more below"
 *     className="absolute bottom-0 left-1/2 -translate-x-1/2"
 *   />
 *  </div>
 * );
 * ```
 */
export const ThemedBelowFoldIndicator = <T extends HTMLElement>({
  container,
  selector,
  text,
  ...props
}: ThemedBelowFoldIndicatorProps<T>) => {
  const indicator = useRef<HTMLDivElement>(null);
  const textContent = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const currentContainer = container?.current;

    if (!currentContainer) {
      return;
    }

    const handleScroll = () => {
      if (!indicator.current || !container.current || !textContent.current) {
        return;
      }

      const elements = currentContainer.querySelectorAll(
        selector
      ) as NodeListOf<HTMLElement>;

      const containerBounds = currentContainer.getBoundingClientRect();

      const outOfViewElements = Array.from(elements).filter((element) => {
        const rect = element.getBoundingClientRect();
        return rect.top + rect.height / 2 > containerBounds.bottom;
      });

      const numOutOfView = outOfViewElements.length;
      const content = text.replace(
        '{{amount}}',
        Math.max(1, numOutOfView).toString()
      );

      indicator.current.style.opacity = numOutOfView > 0 ? '1' : '0';
      textContent.current.textContent = content;
    };

    handleScroll();

    currentContainer.addEventListener('scroll', handleScroll);

    return () => {
      currentContainer.removeEventListener('scroll', handleScroll);
    };
  }, [container, text]);

  return (
    <div
      aria-hidden
      {...props}
      className={clsx(
        'pointer-events-none flex items-center gap-2 rounded-xl p-3 transition-opacity',
        '[background:color-mix(in_srgb,var(--typography-paragraph-textColor)_10%,var(--canvas-backgroundColor))]',
        props.className
      )}
      ref={indicator}
    >
      <ArrowDown weight="bold" />
      <span ref={textContent} className="bits-text-subtitle-2">
        {text}
      </span>
    </div>
  );
};
