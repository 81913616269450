export const validateDanishPersonalIdentityNumber = (id: string) => {
  const match = /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])(\d{2})-?(\d{4})$/.test(
    id
  );
  if (!match) {
    return false;
  }

  const day = parseInt(id.substring(0, 2), 10);
  const month = parseInt(id.substring(2, 4), 10);
  const year = parseInt(id.substring(4, 6), 10);

  if (year < 0 || year > 99) {
    return false;
  }

  if (month < 1 || month > 12) {
    return false;
  }

  return !(day < 1 || day > new Date(year, month, 0).getDate());
};
