import React, { HTMLProps } from 'react';
import { Slot } from '@radix-ui/react-slot';

import { cn } from '../utils/cn';

export interface ThemedTextProps
  extends Omit<HTMLProps<HTMLParagraphElement>, 'ref'> {
  asChild?: boolean;
}

export const ThemedText = ({
  asChild = false,
  className,
  ...props
}: ThemedTextProps) => {
  const Comp = asChild ? Slot : 'p';
  return (
    <Comp
      className={cn(
        '[color:var(--typography-paragraph-textColor)] [font-weight:var(--typography-paragraph-fontWeight)] [font-size:var(--typography-paragraph-fontSize)] [letter-spacing:var(--typography-paragraph-letterSpacing)] [text-transform:var(--typography-paragraph-uppercase)]',
        className
      )}
      {...props}
    />
  );
};
