import React from 'react';

export const InputErrorAndHint = ({
  hint,
  error,
}: {
  hint?: string;
  error?: string;
}) => (
  <>
    {hint && <p className="bits-text-caption text-shadow mt-1">{hint}</p>}
    {error && <p className="bits-text-caption text-grapefruit mt-1">{error}</p>}
  </>
);
