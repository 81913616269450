import * as React from 'react';
import { WarningCircle } from '@phosphor-icons/react';

import { cn } from './utils/cn';

const Callout = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'border-ink bg-sand-light flex items-center bits-text-button-2 gap-2.5 rounded-md border p-3',
      '[&_a]:underline',
      className
    )}
    {...props}
  >
    <WarningCircle className="shrink-0 h-6 w-6" />
    <div>{children}</div>
  </div>
));
Callout.displayName = 'Callout';

export { Callout };
