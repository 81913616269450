import { ReactElement, ReactNode, Suspense, useEffect } from 'react';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import { FeatureFlagProvider } from '@bits/feature-flags';
import { aktivGrotesk, gelica } from '@bits/fonts';
import { AutomaticEnvironmentIndicator, Toaster } from '@bits/ui';
import { SessionProvider } from 'next-auth/react';

import '@/styles/globals.css';
import '../i18n';

import Head from 'next/head';

import { Datadog } from '@/lib/datadog';
import { FeatureFlagContextSetters } from '@/components/feature-flag-context-setters/feature-flag-context-setters';
import { PageNavigationIndicator } from '@/components/page-navigation-indicator/page-navigation-indicator';

export type NextPageWithLayout<P = Record<string, never>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithLayout) {
  const getLayout = Component.getLayout || ((page) => page);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      document.body.classList.add(
        aktivGrotesk.className,
        aktivGrotesk.variable,
        gelica.variable
      );
    }
  }, []);

  return (
    /** * Suspense is used to wrap the entire app to allow for lazy loading of
     components. * * This is needed by i18n-next * @see
     https://youtu.be/SA_9i4TtxLQ?t=1007 */
    <Suspense fallback={null}>
      <FeatureFlagProvider suspend={true}>
        <SessionProvider session={session}>
          <FeatureFlagContextSetters />
          <Datadog />
          <Toaster />
          <Head>
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              href="/bits-favicon-dark.png"
              media="(prefers-color-scheme: light)"
            />
            <link
              rel="icon"
              type="image/png"
              href="/bits-favicon-light.png"
              media="(prefers-color-scheme: dark)"
            />
            <link
              rel="manifest"
              href="/site.webmanifest"
              crossOrigin="use-credentials"
            />
            <link
              rel="mask-icon"
              href="/safari-pinned-tab.svg"
              color="#5bbad5"
            />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff" />
            <title>Dashboard | Bits Technology</title>
          </Head>
          <div className="main fixed inset-0">
            {getLayout(<Component {...pageProps} />)}
          </div>

          <AutomaticEnvironmentIndicator />
        </SessionProvider>
        <PageNavigationIndicator />
      </FeatureFlagProvider>
    </Suspense>
  );
}
