import { ElementType, forwardRef } from 'react';
import { Slot, Slottable } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';

import { SmallSpinner } from './small-spinner';
import { cn } from './utils/cn';

export const bitsButtonClassNames = cva(
  [
    'inline-flex items-center justify-center gap-3 border-2',
    'transition-all duration-75 active:transition-none',
    'cursor-pointer',
  ],
  {
    variants: {
      size: {
        medium: 'bits-text-button-1 px-6 py-3',
        small: 'bits-text-button-2 px-4 py-2',
      },
      variant: {
        primary: [
          'bg-ink border-transparent text-white',
          'hover:bg-black',
          'active:border-white active:bg-black',
        ],
        secondary: [
          'bg-fog text-ink border-transparent',
          'hover:bg-smoke',
          'active:bg-shadow',
        ],
        outline: [
          'border-ink text-ink bg-transparent',
          'hover:bg-fog',
          'active:bg-smoke',
        ],
        destructive: [
          'bg-grapefruit text-ink border-transparent',
          'hover:bg-grapefruit-dark',
          'active:bg-grapefruit-dark active:border-ink',
        ],
        destructiveOutline: [
          'border-grapefruit text-ink bg-transparent',
          'hover:bg-grapefruit-light',
          'active:bg-grapefruit-dark',
        ],
        destructiveLink: ['text-grapefruit underline'],
        ghost: [
          'text-ink border-transparent bg-transparent',
          'hover:bg-fog',
          'active:bg-smoke',
        ],
        constructive: [
          'bg-kiwi-light text-ink border-transparent',
          'hover:bg-kiwi',
          'active:bg-kiwi-kiwi active:border-ink',
        ],
        link: ['underline'],
        white: [
          'text-ink border-transparent bg-white',
          'hover:text-ink hover:bg-fog',
          'active:text-ink active:border-smoke active:bg-white',
        ],
      },
      shape: {
        pill: 'rounded-full',
        squarish: 'rounded-md',
        icon: 'rounded-full',
      },
      disabled: {
        true: 'pointer-events-none opacity-40',
      },
    },
    compoundVariants: [
      {
        size: 'small',
        shape: 'icon',
        className: 'p-2',
      },
      {
        size: 'medium',
        shape: 'icon',
        className: 'p-3',
      },
      {
        size: ['small', 'medium'],
        variant: ['link', 'destructiveLink'],
        className: [
          'gap-1 border-0 bg-transparent p-0',
          'hover:bg-transparent hover:opacity-50',
          'active:bg-transparent active:opacity-100',
        ],
      },
    ],
    defaultVariants: {
      size: 'medium',
      variant: 'primary',
      shape: 'squarish',
    },
  }
);

export interface BitsButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?:
    | 'primary'
    | 'secondary'
    | 'outline'
    | 'destructive'
    | 'destructiveOutline'
    | 'destructiveLink'
    | 'ghost'
    | 'constructive'
    | 'link'
    | 'white';
  size?: 'medium' | 'small';
  shape?: 'pill' | 'squarish' | 'icon';
  icon?: ElementType;
  iconPlacement?: 'left' | 'right';
  loading?: boolean;
  asChild?: boolean;
  disabled?: boolean;
}

export const BitsButton = forwardRef<HTMLButtonElement, BitsButtonProps>(
  (
    {
      asChild,
      children,
      className,
      size,
      variant,
      shape,
      icon: Icon,
      iconPlacement = 'left',
      loading,
      type = 'button',
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(
          bitsButtonClassNames({
            size,
            variant,
            shape,
            disabled: props.disabled || loading,
            className,
          })
        )}
        {...props}
        type={type}
        ref={ref}
        disabled={props.disabled || loading}
        aria-disabled={props.disabled || loading}
      >
        {Icon && !loading && iconPlacement === 'left' && (
          <Icon className="block size-4 shrink-0" weight={'bold'} />
        )}
        {loading && iconPlacement == 'left' && <SmallSpinner />}
        <Slottable>{children}</Slottable>
        {Icon && !loading && iconPlacement === 'right' && (
          <Icon className="block size-4 shrink-0" weight={'bold'} />
        )}
        {loading && iconPlacement == 'right' && <SmallSpinner />}
      </Comp>
    );
  }
);

BitsButton.displayName = 'BitsButton';
