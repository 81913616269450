import { memo, useEffect, useState } from 'react';
import { cva } from 'class-variance-authority';

const styleClasses = cva('', {
  variants: {
    environment: {
      development: 'bg-kiwi-light',
      staging: 'bg-mangosteen',
      sandbox: 'bg-tangerine',
    },
  },
});

const hidableEnvironments = ['development', 'staging'];

export type EnvironmentIndicatorProps = {
  environment?: 'development' | 'staging' | 'sandbox' | 'production';
};

export const EnvironmentIndicator = memo(
  ({ environment }: EnvironmentIndicatorProps) => {
    const hidable = hidableEnvironments.includes(environment || '');
    const [isHidden, setIsHidden] = useState(true);

    useEffect(() => {
      const hidden =
        hidable &&
        Number(localStorage.getItem('hide-environment-notice') || 0) >
          Date.now();

      setIsHidden(hidden);
    });

    if (!environment || environment === 'production' || isHidden) return null;

    return (
      <div
        aria-hidden
        className={styleClasses({
          environment,
          className: 'group fixed inset-x-0 top-0 h-1',
        })}
      >
        <div
          className={styleClasses({
            environment,
            className:
              'absolute left-1/2 -translate-x-1/2 rounded-b-md px-2 py-1 text-xs text-ink cursor-pointer',
          })}
        >
          {environment}{' '}
          {hidable && (
            <span
              className="hidden group-hover:inline-block opacity-50"
              onClick={() => {
                localStorage.setItem(
                  'hide-environment-notice',
                  // Hide until 1hr from now
                  String(Date.now() + 1000 * 60 * 60)
                );
                setIsHidden(true);
              }}
            >
              {' '}
              &mdash; hide notice for 1hr
            </span>
          )}
        </div>
      </div>
    );
  }
);

EnvironmentIndicator.displayName = 'EnvironmentIndicator';

export const AutomaticEnvironmentIndicator = memo(() => {
  const [environment, setEnvironment] =
    useState<EnvironmentIndicatorProps['environment']>('production');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setEnvironment(getBitsEnvironment());
    }
  }, []);

  return <EnvironmentIndicator environment={environment} />;
});

AutomaticEnvironmentIndicator.displayName = 'AutomaticEnvironmentIndicator';

export const getBitsEnvironment = () => {
  if (typeof window !== 'undefined') {
    // Allow overriding the environment in e2e tests etc
    if ('_BITS_ENV' in window)
      return window._BITS_ENV as
        | 'development'
        | 'staging'
        | 'sandbox'
        | 'production';

    const hostname = window.location.hostname;

    if (hostname === 'localhost' || hostname.includes('.local')) {
      return 'development';
    } else if (hostname.includes('bits.beer')) {
      return 'staging';
    } else if (hostname.includes('bits.builders')) {
      return 'sandbox';
    }
  }

  return 'production';
};
