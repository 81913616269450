import { objectToCustomerTheme } from '..';

/**
 * Creates color classes based on the available variables.
 *
 * @example
 * '--buttons-primary-hover-backgroundColor' -> 'hover:bg-theme-buttons-primary-hover-backgroundColor'
 */
export const bitsThemeTailwindColors = () => {
  return Object.entries(objectToCustomerTheme({}).cssVariables).reduce<
    Record<string, string>
  >((colors, [key, value]) => {
    if (key.toLowerCase().includes('color')) {
      colors['theme-' + key.replace('--', '')] =
        `color-mix(in srgb, var(${key}, ${value}) calc(100% * <alpha-value>), transparent)`;
    }

    return colors;
  }, {});
};

const tailwindObjectFactory = (type: string) => () =>
  Object.entries(objectToCustomerTheme({}).cssVariables).reduce<
    Record<string, string>
  >((sizes, [key, value]) => {
    if (key.toLowerCase().includes('fontsize')) {
      sizes['theme-' + key.replace('--', '')] = `var(${key}, ${value})`;
    }

    return sizes;
  }, {});

export const bitsThemeTailwindFontSizes = tailwindObjectFactory('fontsize');
export const bitsThemeTailwindBorderWidths =
  tailwindObjectFactory('borderwidth');
export const bitsThemeTailwindBorderRadii =
  tailwindObjectFactory('cornerradius');
