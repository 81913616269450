import { CSSProperties } from 'react';

import { cn } from './utils/cn';

export type HRWithLabelProps = { label: string };

export const HRWithLabel = ({ label }: HRWithLabelProps) => {
  return (
    <div
      className={cn(
        'relative border-fog border-b overflow-visible',
        'after:absolute after:content-[var(--after-content)] after:-bottom-4',
        'after:bits-text-button-2 after:border-fog after:text-ink after:rounded-full',
        'after:border after:bg-white after:px-4 after:py-2 after:right-1/2 after:translate-x-1/2'
      )}
      style={
        {
          '--after-content': `"${label}"`,
        } as CSSProperties
      }
    >
      <hr />
    </div>
  );
};
