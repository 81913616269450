import { datadogRum } from '@datadog/browser-rum';

import { env } from '@/env/env.mjs';

if (
  env.NEXT_PUBLIC_VERCEL_ENV &&
  ['preview', 'production'].includes(env.NEXT_PUBLIC_VERCEL_ENV) &&
  env.NEXT_PUBLIC_DATADOG_APPLICATION_ID &&
  env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN
) {
  datadogRum.init({
    applicationId: env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
    clientToken: env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: 'dashboard',
    // Environment name
    // e.g. production:main, preview:hugo/some-feature-branch, production:prod
    env: `${env.NEXT_PUBLIC_VERCEL_ENV}:${env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF}`,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    sessionSampleRate: 100,
    // We do not want session recordings
    sessionReplaySampleRate: 0,
    trackUserInteractions: false,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    // Specify URLs to propagate trace headers for connection between RUM and backend trace
    allowedTracingUrls: [{ match: '/api/', propagatorTypes: ['tracecontext'] }],
  });
}

export const Datadog = () => null;
