export const validateNorwayOrgNumber = (number: string) => {
  const norwayOrgNumberRegex = /^[0-9]{3}\s?[0-9]{3}\s?[0-9]{3}$/g;

  if (!norwayOrgNumberRegex.test(number)) {
    return false;
  }

  const trimmedNumber = number.replace(/\s/g, '');

  if (trimmedNumber.length !== 9) {
    return false;
  }

  const idPart = trimmedNumber.substring(0, 8);
  const checksumPart = trimmedNumber.substring(8, 9);

  const multipliers = [3, 2, 7, 6, 5, 4, 3, 2];
  const sum = multipliers.reduce(
    (acc, curr, idx) => acc + curr * parseInt(idPart.charAt(idx)),
    0
  );

  const checksum = 11 - (sum % 11);
  if (checksum === 10) {
    return false;
  }
  if (checksum === 11) {
    return checksumPart === '0';
  }
  return checksum.toString() === checksumPart;
};
