/**
 Validates org numbers from Switzerland
 */
export const validateSwitzerlandOrgNumber = (number: string) => {
  const switzerlandNewOrgNumberRegex = /^(CH)-\d{3}-\d{7}-\d$/g;
  const switzerlandOldOrgNumberRegex = /^(CH)-\d{3}\.\d{1}\.\d{3}\.\d{3}-\d$/g;

  const switzerlandTaxNumberRegex = /^(CHE)-\d{3}\.\d{3}\.\d{3}$/g;

  return (
    switzerlandNewOrgNumberRegex.test(number) ||
    switzerlandOldOrgNumberRegex.test(number) ||
    switzerlandTaxNumberRegex.test(number)
  );
};
