export const validateSwedenOrgNumber = (number: string) => {
  // 6 or 8 digits followed by an optional -, followed by 4 digits
  const swedenOrgNumberRegex = /^(\d{6}|\d{8})[-]?\d{4}$/g;

  if (!swedenOrgNumberRegex.test(number)) {
    return false;
  }

  number = number.replace(/-/gi, '');

  if (number.length === 12) {
    number = number.substring(2);
  }

  if (number.length !== 10) {
    return false;
  }

  const len = number.length;
  const parity = len % 2;
  let sum = 0;
  for (let i = len - 1; i >= 0; i--) {
    let d = parseInt(number.charAt(i));
    if (i % 2 == parity) {
      d *= 2;
    }
    if (d > 9) {
      d -= 9;
    }
    sum += d;
  }
  return sum % 10 == 0;
};
