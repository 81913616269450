/**
 Validates org numbers from Italy
 */
export const validateItalyOrgNumber = (number: string) => {
  const italyOrgNumberRegex = /^[A-Z]{2}\d{7}$/g;

  const italyTaxNumberRegex = /^\d{11}$/g;

  return italyOrgNumberRegex.test(number) || italyTaxNumberRegex.test(number);
};
