import * as React from 'react';

const newlineRegex = /(\r\n|\r|\n)/g;

/**
 * Converts newlines to <br />.
 * We all love PHP's nl2br.
 *
 * @param {string} str to convert newlines to <br />
 * @returns {React.ReactNode}
 */
export const nl2br = (str: string) => {
  return str.split(newlineRegex).map((str, index) => (
    <React.Fragment key={index}>
      {str.match(newlineRegex) ? (
        <>
          {str}
          <br />
        </>
      ) : (
        str
      )}
    </React.Fragment>
  ));
};
