'use client';

import * as React from 'react';
import { Check } from '@phosphor-icons/react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';

import { cn } from '../utils/cn';
import { useUuid } from '../utils/use-uuid';

export interface ThemedCheckboxProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
    'label'
  > {
  label: string;
  error?: string;
}

export const ThemedCheckbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  ThemedCheckboxProps
>(({ className, label, error, ...props }, ref) => {
  const id = useUuid();

  return (
    <label
      className={cn(
        'flex gap-2 items-start cursor-pointer',
        props.disabled && 'cursor-not-allowed opacity-50'
      )}
    >
      <CheckboxPrimitive.Root
        ref={ref}
        className={cn(
          'relative flex [top:calc(var(--typography-paragraph-fontSize)-16px)] peer [width:calc(20px+var(--inputs-borderWidth))] [height:calc(20px+var(--inputs-borderWidth))] shrink-0',
          'border-theme-inputs-labelTextColor [border-width:max(var(--inputs-borderWidth),1px)] [border-radius:min(5px,var(--inputs-cornerRadius))] [box-shadow:var(--inputs-shadow)]',
          'outline-none focus-visible:outline-2 focus-visible:outline-theme-general-focusColor',
          'data-[state=unchecked]:bg-theme-inputs-checkboxesAndRadios-unchecked-backgroundColor data-[state=checked]:bg-theme-inputs-checkboxesAndRadios-checked-backgroundColor',
          'data-[state=unchecked]:border-theme-inputs-checkboxesAndRadios-unchecked-borderColor data-[state=checked]:border-theme-inputs-checkboxesAndRadios-checked-borderColor',
          error && '!border-theme-inputs-errorColor',
          className
        )}
        aria-labelledby={id}
        {...props}
      >
        <CheckboxPrimitive.Indicator
          className={cn(
            'text-theme-inputs-checkboxesAndRadios-indicatorColor m-auto animate-in zoom-in-125'
          )}
        >
          <Check className="size-4" weight="bold" />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
      <p
        aria-hidden
        className={cn(
          'text-theme-typography-paragraph-fontSize text-theme-inputs-labelTextColor',
          error && 'text-theme-inputs-errorColor'
        )}
        id={id}
      >
        {label}
      </p>
    </label>
  );
});

ThemedCheckbox.displayName = CheckboxPrimitive.Root.displayName;
