/**
 Validates Finnish org numbers, e.g. 1234567-8 or 12345678
 @see https://www.vero.fi/globalassets/tietoa-verohallinnosta/ohjelmistokehittajille/finnish-tax-administration_the-control-character-for-verifying-the-authenticity-of-finnish-business-ids-and-personal-identity-codes.pdf
 */
export const validateFinlandOrgNumber = (number: string) => {
  const finlandOrgNumberRegex = /^[0-9]{7}[-][0-9]{1}$/g;
  const acceptableOrgNumberRegex = /^[0-9]{8}$/g;

  if (acceptableOrgNumberRegex.test(number)) {
    number = number.substring(0, 7) + '-' + number.substring(7);
  }

  if (!finlandOrgNumberRegex.test(number)) {
    return false;
  }

  if (number.length !== 9) {
    return false;
  }

  const idPart = number.substring(0, 7);
  const checksumPart = number.substring(8, 9);

  const multipliers = [7, 9, 10, 5, 8, 4, 2];
  const sum = multipliers.reduce(
    (acc, curr, idx) => acc + curr * parseInt(idPart.charAt(idx)),
    0
  );

  const checksum = 11 - (sum % 11);
  if (checksum === 10) {
    return false;
  }
  if (checksum === 11) {
    return checksumPart === '0';
  }
  return checksum.toString() === checksumPart;
};
