import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MagnifyingGlass, Minus, Plus } from '@phosphor-icons/react';
import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from '@radix-ui/react-tooltip';

import { RoundButton } from './round-button';
import { cn } from './utils/cn';

interface ZoomableCanvasState {
  zoom: number;
  x: number;
  y: number;
}

export type ZoomableCanvasContext = {
  setZoom: (zoom: number | ((currentZoom: number) => number)) => void;
  translateTo: (
    x: number,
    y: number,
    transformOptions: Parameters<ZoomableCanvasContext['updateTransform']>[0]
  ) => void;
  /**
   * Centers content in the canvas based on the contentBounds
   * top, left, width, height
   * @param contentBounds
   */
  centerContent: (
    contentBounds?: {
      left: number;
      top: number;
      width: number;
      height: number;
    },
    options?: Parameters<ZoomableCanvasContext['updateTransform']>[0]
  ) => void;
  liveState: ZoomableCanvasState;
  staticState: ZoomableCanvasState;
  containerRef: React.RefObject<HTMLDivElement> | null;
  canvasRef: React.RefObject<HTMLDivElement> | null;
  updateTransform: (options?: { smooth: boolean }) => void;
  settings: {
    minZoom: number;
    maxZoom: number;
    ctrlScrollToZoom: boolean;
  };
};

const zoomableCanvasContext = createContext<ZoomableCanvasContext>({
  setZoom: (zoom: number | ((currentZoom: number) => number)) => {},
  translateTo: (x: number, y: number) => {},
  centerContent: (contentBounds?: {
    left: number;
    top: number;
    width: number;
    height: number;
  }) => {},
  liveState: {} as ZoomableCanvasState,
  staticState: {} as ZoomableCanvasState,
  containerRef: null,
  canvasRef: null,
  updateTransform: () => {},
  settings: {
    minZoom: 0.5,
    maxZoom: 2,
    ctrlScrollToZoom: true,
  },
});

export const useZoomableCanvas = () => useContext(zoomableCanvasContext);

export const ZoomableCanvasProvider = ({
  children,
  minZoom = 0.5,
  maxZoom = 2,
  ctrlScrollToZoom = true,
}: {
  minZoom?: number;
  maxZoom?: number;
  ctrlScrollToZoom?: boolean;
  children?: ReactNode;
}) => {
  const canvasRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [staticState] = useState({
    zoom: 1,
    x: 0,
    y: 0,
  });

  const [liveState, setLiveState] = useState<ZoomableCanvasState>({
    x: 0,
    y: 0,
    zoom: 1,
  });

  const updateTransform = ({ smooth = false } = {}) => {
    if (canvasRef.current && containerRef.current) {
      if (!smooth) {
        canvasRef.current.style.transition = 'none';
      } else {
        canvasRef.current.style.transition = 'all .4s ease-out';
      }

      canvasRef.current.style.transform = `scale(${staticState.zoom}) translate(${staticState.x}px, ${staticState.y}px)`;

      containerRef.current.style.backgroundSize = `${24 * staticState.zoom}px ${
        24 * staticState.zoom
      }px`;
      containerRef.current.style.backgroundPosition = `${
        staticState.x * staticState.zoom
      }px ${staticState.y * staticState.zoom}px`;

      setLiveState({ ...staticState });
    }
  };

  const setZoom = (zoom: number | ((currentZoom: number) => number)) => {
    const newZoom = Math.max(
      minZoom,
      Math.min(
        maxZoom,
        typeof zoom === 'function' ? zoom(staticState.zoom) : zoom
      )
    );
    const zoomRatio = newZoom / staticState.zoom;

    const rect = containerRef.current!.getBoundingClientRect();

    staticState.x -=
      rect.width / 2 / (newZoom / zoomRatio) - rect.width / 2 / newZoom;
    staticState.y -=
      rect.height / 2 / (newZoom / zoomRatio) - rect.height / 2 / newZoom;

    staticState.zoom = newZoom;

    updateTransform({ smooth: false });
  };

  const translateTo: ZoomableCanvasContext['translateTo'] = (
    x,
    y,
    transformOptions = { smooth: true }
  ) => {
    staticState.x = x;
    staticState.y = y;
    updateTransform(transformOptions);
  };

  const centerContent: ZoomableCanvasContext['centerContent'] = (
    contentBounds,
    transformOptions
  ) => {
    if (!canvasRef.current || !containerRef.current) return;

    const rect = contentBounds || canvasRef.current.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();

    // Center the content and zoom to fit the container
    const minHeight = containerRect.height / rect.height;
    const minWidth = containerRect.width / rect.width;

    const biggerThanContainer =
      rect.width > containerRect.width || rect.height > containerRect.height;

    let zoom = biggerThanContainer ? Math.min(minHeight, minWidth) : 1;
    let cannotFit = false;
    if (zoom < minZoom) {
      zoom = minZoom;
      cannotFit = true;
    }

    const x =
      (!cannotFit ? containerRect.width / 2 / zoom - rect.width / 2 : 0) -
      rect.left;
    const y = containerRect.height / 2 / zoom - rect.height / 2 - rect.top;

    setZoom(zoom);
    translateTo(x, y, transformOptions);
  };

  const contextValue = useMemo(
    () => ({
      setZoom,
      translateTo,
      centerContent,
      liveState,
      staticState,
      containerRef,
      canvasRef,
      updateTransform,
      settings: {
        minZoom,
        maxZoom,
        ctrlScrollToZoom,
      },
    }),
    [liveState]
  );

  return (
    <zoomableCanvasContext.Provider value={contextValue}>
      {children}
    </zoomableCanvasContext.Provider>
  );
};

export interface ZoomableCanvasProps {
  children: ReactNode;
  overlay?: ReactNode;
}

/**
 * A Zoomable Canvas component
 * Let's the user move the canvas around and zoom in and out
 * using pinch controls, as well scroll wheel
 *
 * Zoom is focused on the cursor position.
 *
 * @example
 * <ZoomableCanvas minZoom={.5} maxZoom={2}>
 *   <div className="absolute top-0 left-0">Canvas content here</div>
 *   <div className="absolute top-[400px] left-0">Canvas content here</div>
 * </ZoomableCanvas>
 */
export const ZoomableCanvas: React.FC<ZoomableCanvasProps> = ({
  overlay,
  children,
}) => {
  const { containerRef, canvasRef, staticState, updateTransform, settings } =
    useZoomableCanvas();

  const ctrlScrollInfoRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const handleWheel = (event: WheelEvent) => {
    if (!containerRef?.current) {
      return;
    }

    if (
      ctrlScrollInfoRef.current &&
      settings.ctrlScrollToZoom &&
      !event.ctrlKey
    ) {
      clearTimeout(timeoutRef.current);
      ctrlScrollInfoRef.current.style.opacity = '1';

      timeoutRef.current = setTimeout(() => {
        if (ctrlScrollInfoRef.current)
          ctrlScrollInfoRef.current.style.opacity = '0';
      }, 1000);

      return;
    }

    event.preventDefault();
    event.stopPropagation();

    const zoomFactor = 0.005;
    const deltaY = Math.min(20, Math.max(-20, event.deltaY));
    const newZoom = Math.max(
      settings.minZoom,
      Math.min(settings.maxZoom, staticState.zoom - deltaY * zoomFactor)
    );

    const rect = containerRef.current.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;
    const mouseY = event.clientY - rect.top;
    const zoomRatio = newZoom / staticState.zoom;

    staticState.x -= mouseX / (newZoom / zoomRatio) - mouseX / newZoom;
    staticState.y -= mouseY / (newZoom / zoomRatio) - mouseY / newZoom;

    staticState.zoom = newZoom;

    updateTransform();
  };

  useEffect(() => {
    containerRef?.current?.addEventListener('wheel', handleWheel, {
      passive: false,
    });
    containerRef?.current?.addEventListener('touchstart', handleTouchStart, {
      passive: false,
    });

    return () => {
      containerRef?.current?.removeEventListener('wheel', handleWheel);
      containerRef?.current?.removeEventListener(
        'touchstart',
        handleTouchStart
      );
    };
  }, []);

  /**
   * Handle zooming and panning with 2 fingers!
   */
  const handleTouchStart = (event: TouchEvent) => {
    const touch1 = event.touches[0];
    const touch2 = event.touches[1];

    if (!touch2 || !touch1) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    let startMidPoint = {
      x: (touch1.clientX + touch2.clientX) / 2,
      y: (touch1.clientY + touch2.clientY) / 2,
    };

    let startDistance = Math.hypot(
      touch1.clientX - touch2.clientX,
      touch1.clientY - touch2.clientY
    );

    const handleTouchMove = (event: TouchEvent) => {
      const touch1 = event.touches[0];
      const touch2 = event.touches[1];

      if (!touch1 || !touch2) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      const midPoint = {
        x: (touch1.clientX + touch2.clientX) / 2,
        y: (touch1.clientY + touch2.clientY) / 2,
      };

      const deltaX = touch1.clientX - touch2.clientX;
      const deltaY = touch1.clientY - touch2.clientY;

      const distance = Math.hypot(deltaX, deltaY);
      const newZoom = Math.max(
        settings.minZoom,
        Math.min(
          settings.maxZoom,
          staticState.zoom / (startDistance / distance)
        )
      );

      if (containerRef?.current) {
        const rect = containerRef?.current.getBoundingClientRect();
        const mouseX = midPoint.x - rect.left;
        const mouseY = midPoint.y - rect.top;
        const zoomRatio = newZoom / staticState.zoom;

        staticState.x -= (startMidPoint.x - midPoint.x) / staticState.zoom;
        staticState.y -= (startMidPoint.y - midPoint.y) / staticState.zoom;

        staticState.x -= mouseX / (newZoom / zoomRatio) - mouseX / newZoom;
        staticState.y -= mouseY / (newZoom / zoomRatio) - mouseY / newZoom;

        staticState.zoom = newZoom;

        startDistance = distance;
        startMidPoint = midPoint;

        updateTransform();
      }
    };

    const handleTouchEnd = () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };

    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    let startX = event.clientX;
    let startY = event.clientY;

    const handleMouseMove = (event: MouseEvent) => {
      const deltaX = event.clientX - startX;
      const deltaY = event.clientY - startY;

      if (canvasRef?.current) {
        canvasRef.current.style.transition = 'none';
        staticState.x += deltaX / staticState.zoom;
        staticState.y += deltaY / staticState.zoom;

        startX = event.clientX;
        startY = event.clientY;

        updateTransform();
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  useLayoutEffect(() => {
    updateTransform();
  }, []);

  return (
    <div
      ref={containerRef}
      className={cn(
        'absolute inset-0 overflow-hidden cursor-grab active:cursor-grabbing',
        '[background:radial-gradient(rgba(0,0,0,.16)_1px,_transparent_0)] [background-size:24px_24px] bg-repeat'
      )}
      onMouseDown={handleMouseDown}
    >
      <div
        ref={canvasRef}
        className="relative"
        style={{
          transformOrigin: 'top left',
        }}
      >
        {children}
      </div>

      {overlay}

      <div
        ref={ctrlScrollInfoRef}
        className="pointer-events-none absolute inset-0 flex items-center justify-center bg-black/50 text-white opacity-0 transition-all"
      >
        Use Ctrl + Scroll to zoom
      </div>
    </div>
  );
};

export type ZoomableCanvasControlsProps = {
  extraActions?: ReactNode;
};

export const ZoomableCanvasControls = ({
  extraActions,
}: ZoomableCanvasControlsProps) => {
  const { setZoom, liveState } = useZoomableCanvas();

  return (
    <div className="shadow-soft absolute bottom-4 left-1/2 flex -translate-x-1/2 cursor-auto items-center gap-2 rounded-full bg-white px-3 py-2">
      <MagnifyingGlass className="size-6" onClick={() => setZoom(1)} />
      <span className="bits-text-button-2 pointer-events-none block w-8 select-none text-center font-mono">
        {Math.round(liveState.zoom * 100)}%
      </span>
      <ZoomableCanvasTooltip content="Zoom in">
        <RoundButton
          icon={Plus}
          onClick={() => setZoom((zoom) => zoom + 0.1)}
        />
      </ZoomableCanvasTooltip>
      <ZoomableCanvasTooltip content="Zoom out">
        <RoundButton
          icon={Minus}
          onClick={() => setZoom((zoom) => zoom - 0.1)}
        />
      </ZoomableCanvasTooltip>
      {extraActions}
    </div>
  );
};

export const ZoomableCanvasTooltip = ({
  children,
  content = '',
}: {
  children: ReactNode;
  content: ReactNode;
}) => (
  <TooltipProvider delayDuration={0}>
    <Tooltip>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      {/* <TooltipPortal> */}
      <TooltipContent className="bg-ink bits-text-caption animate-in fade-in-0 pointer-events-none max-w-[240px] rounded-lg px-3 py-1 text-center text-white">
        {content}
      </TooltipContent>
      {/* </TooltipPortal> */}
    </Tooltip>
  </TooltipProvider>
);
