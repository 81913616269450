import { HTMLProps, ReactNode } from 'react';
import { Circle } from '@phosphor-icons/react';

import { cn } from './utils/cn';

export const CardRadioGroup = (props: HTMLProps<HTMLDivElement>) => {
  return (
    <div
      role="radiogroup"
      {...props}
      className={cn('grid gap-3', props.className)}
    />
  );
};

export const CardRadioItem = ({
  label,
  description,
  checked,
  onCheckedChange,
  value,
  disabled,
}: {
  label?: ReactNode;
  description?: ReactNode;
  onCheckedChange: (value: string) => void;
  checked: boolean;
  value: string;
  disabled?: boolean;
}) => {
  return (
    <button
      role="radio"
      aria-checked={checked}
      onClick={() => {
        onCheckedChange?.(value);
      }}
      className={cn(
        'flex text-left gap-2 p-4 justify-between cursor-pointer border-2 border-fog rounded-lg',
        'hover:bg-tangerine-light/20 transition-all',
        disabled && 'cursor-not-allowed opacity-50',
        checked && 'border-ink'
      )}
    >
      <div className={cn('text-ink bits-text-body-1', 'grid gap-1')}>
        <p className="bits-text-subtitle-1">{label}</p>
        {description && (
          <p className="text-ink/60 bits-text-body-2">{description}</p>
        )}
      </div>
      <div
        aria-hidden
        className={cn('border h-5 w-5 flex shrink-0 rounded-full')}
      >
        {checked && (
          <span className="animate-in zoom-in-50 relative m-auto flex items-center justify-center">
            <Circle weight="fill" className="text-ink size-[11px]" />
          </span>
        )}
      </div>
    </button>
  );
};
