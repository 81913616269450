import React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { VariantProps } from 'class-variance-authority';

import { ButtonProps, buttonVariants } from './base-button';
import { cn } from './utils/cn';

const buttonClass = 'bits-text-button-2 rounded-full h-9 px-4';

export const mediumButtonVariants = (
  variant: VariantProps<typeof buttonVariants>['variant']
) => {
  return buttonVariants({ variant });
};

export interface MediumButtonProps extends ButtonProps {}

export const MediumButton = React.forwardRef<
  HTMLButtonElement,
  MediumButtonProps
>(
  (
    { asChild, children, className, variant, icon: Icon, iconProps, ...props },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <button
        className={cn(buttonClass, buttonVariants({ variant, className }))}
        ref={ref}
        {...props}
      >
        {Icon ? (
          <Icon
            {...iconProps}
            className={cn('h-4 w-4', iconProps?.className)}
          />
        ) : null}
        {children}
      </button>
    );
  }
);

MediumButton.displayName = 'MediumButton';
