import { ReactNode } from 'react';

import { cn } from '../utils/cn';

export interface InclusiveFiltersProps {
  filters: { value: string; label: ReactNode }[];
  activeFilters: string[];
  onFiltersChange: (filters: string[]) => void;
}

export const InclusiveFilters = ({
  filters,
  activeFilters,
  onFiltersChange,
}: InclusiveFiltersProps) => {
  return (
    <div className="flex gap-2">
      {filters.map(({ value, label }) => (
        <FilterButton
          key={value}
          checked={activeFilters.includes(value)}
          label={label}
          value={value}
          onCheckedChange={(value, pressed, shift) => {
            if (shift) {
              onFiltersChange([value]);
              return;
            }

            if (pressed) {
              onFiltersChange([...activeFilters, value]);
            } else {
              onFiltersChange(activeFilters.filter((f) => f !== value));
            }
          }}
        />
      ))}
    </div>
  );
};

const FilterButton = ({
  checked,
  label,
  value,
  onCheckedChange,
}: {
  checked: boolean;
  label: ReactNode;
  value: string;
  onCheckedChange: (value: string, pressed: boolean, shift: boolean) => void;
}) => {
  return (
    <button
      className={cn(
        'inline-block rounded-md bg-white border border-gray-300 p-1',
        'aria-pressed:border-black'
      )}
      aria-pressed={checked}
      onClick={(event) => {
        onCheckedChange(value, !checked, event.altKey);
      }}
    >
      {label}
    </button>
  );
};
