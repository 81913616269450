import React, { useEffect, useRef, useState } from 'react';
import { SliderProps } from '@radix-ui/react-slider';
import { cva } from 'class-variance-authority';

import { Label } from '../label';
import { Slider } from '../slider';
import { InputErrorAndHint } from './input-error-and-hint';

const inputClasses = cva(
  'border-fog w-[48px] rounded-md border p-0 text-center',
  {
    variants: {
      size: {
        sm: 'h-6',
        md: 'h-10',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  }
);

export const FormSlider = ({
  label,
  error,
  hint,
  value: defaultValue,
  transformDisplayValue = (value) => value,
  min = 0,
  max = 100,
  size = 'md',
  ...props
}: {
  label?: string;
  error?: string;
  hint?: string;
  value?: number;
  transformDisplayValue?: (value: number) => string | number;
  onChange?: (e: { target: { name?: string; value: number } }) => void;
  onValueChange?: (value: number) => void;
  size?: 'sm' | 'md';
} & Omit<SliderProps, 'value'>) => {
  const [value, setValue] = useState(defaultValue || 0);
  const textInput = useRef<HTMLInputElement>(null);
  const sliderInput = useRef<HTMLInputElement>(null);

  const updateTextValue = (value: number, doNotTransform?: boolean) => {
    if (textInput.current) {
      if (doNotTransform) {
        textInput.current.value = String(value);
      } else {
        textInput.current.value = String(transformDisplayValue(value));
      }
    }
  };

  const handleChangeValue = (
    value: number | string,
    doNotTransform?: boolean
  ) => {
    const parsedValue = parseValue(value);
    const val = Math.min(max, Math.max(min, parsedValue));

    props.onChange?.({
      target: {
        name: props.name,
        value: val,
      },
    });
    props.onValueChange?.(val);

    updateTextValue(val, doNotTransform);

    setValue(val);
  };

  useEffect(() => {
    updateTextValue(value, false);
  }, []);

  useEffect(() => {
    if (defaultValue === undefined) {
      return;
    }
    setValue(defaultValue);

    // Don't update the text input if it has focus
    if (document.activeElement !== textInput.current) {
      updateTextValue(defaultValue, false);
    }
  }, [defaultValue]);

  return (
    <Label>
      {label && <p className="bits-text-body-2 mb-2">{label}</p>}
      <div className="flex gap-4">
        <Slider
          {...props}
          value={[value]}
          ref={sliderInput}
          min={min}
          max={max}
          onValueChange={([value]) => {
            handleChangeValue(value || 0);
          }}
          size={size}
        />
        <input
          ref={textInput}
          aria-hidden
          type="text"
          className={inputClasses({ size })}
          defaultValue={value}
          onChange={(e) => {
            handleChangeValue(e.target.value, true);
          }}
          onBlur={(e) => {
            handleChangeValue(e.target.value);
          }}
        />
      </div>
      <InputErrorAndHint hint={hint} error={error} />
    </Label>
  );
};

const parseValue = (value: number | string) => {
  const val = parseFloat(String(value));
  return isNaN(val) ? 0 : val;
};
