import { CSSProperties } from 'react';
import bitsColors from '@bits/tailwind-config/bits-colors';

import { cn } from './utils/cn';

export type Rating = {
  value: number;
  amount: number;
};

export const RatingClusterButtons = ({
  ratings,
  onValueChange,
  colors = [
    bitsColors['kiwi-light'],
    bitsColors['khaki'],
    bitsColors['tangerine-light'],
    bitsColors['grapefruit'],
  ],
}: {
  ratings: Rating[];
  onValueChange: (rating: number) => Promise<void>;
  colors?: string[];
}) => {
  return (
    <div className="flex w-full min-w-16 gap-3">
      {ratings.map((rating) => {
        return (
          <button
            type="button"
            style={
              {
                '--color': colors?.[rating.value - 1],
              } as CSSProperties
            }
            className={cn(
              'group flex gap-2 justify-center bits-text-button-2 w-full rounded-md h-10 items-center border bg-fog-light',
              'transition-all duration-50',
              'hover:border-ink'
            )}
            key={rating.value}
            onClick={() => onValueChange(rating.value)}
          >
            <p className="flex flex-col justify-center py-1.5">
              {rating.amount}
            </p>
            <p
              className={cn(
                'flex flex-col justify-center rounded border',
                'border-[color-mix(in_srgb,var(--color),transparent)] bg-white px-3 py-1.5 group-hover:bg-[color-mix(in_srgb,var(--color),transparent)]',
                rating.amount > 0 && 'bg-[var(--color)]'
              )}
            >
              {rating.value}
            </p>
          </button>
        );
      })}
    </div>
  );
};

export const RatingClusterIndicator = ({
  ratings,
  colors = [
    bitsColors['kiwi-light'],
    bitsColors['khaki'],
    bitsColors['tangerine-light'],
    bitsColors['grapefruit'],
  ],
}: {
  ratings: Rating[];
  colors?: string[];
}) => {
  return (
    <div className="flex min-w-8 gap-3">
      {ratings.map((rating) => {
        return (
          <div
            style={
              {
                '--color': colors?.[rating.value - 1],
              } as CSSProperties
            }
            className={cn(
              'flex gap-2 justify-center bits-text-button-2 min-w-16 rounded-md items-center border bg-white px-2 py-1'
            )}
            key={rating.value}
          >
            <p className="flex flex-col justify-center py-1.5">
              {rating.amount}
            </p>
            <p
              className={cn(
                'flex flex-col justify-center rounded px-3 py-1.5 border border-transparent',
                rating.amount > 0 && 'bg-[var(--color)]',
                rating.amount === 0 &&
                  'border-[color-mix(in_srgb,var(--color),transparent)]'
              )}
            >
              {rating.value}
            </p>
          </div>
        );
      })}
    </div>
  );
};
