import { CSSProperties, HTMLProps } from 'react';
import { CustomerThemeInputSchema } from '@bits/customer-theme';

import { cn } from '../utils/cn';

export const ThemedTopLeftLogo = ({
  theme,
  className,
  maxHeight = 40,
}: HTMLProps<HTMLImageElement> & {
  theme?: CustomerThemeInputSchema;
  maxHeight?: number;
}) =>
  theme ? (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      className={cn('max-h-[var(--max-height)]', className)}
      src={theme?.general?.pageLogo?.url}
      alt=""
      aria-hidden
      style={
        {
          '--max-height': maxHeight + 'px',
        } as CSSProperties
      }
    />
  ) : null;
