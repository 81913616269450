import React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { VariantProps } from 'class-variance-authority';

import { SmallSpinner } from '..';
import { ButtonProps, buttonVariants } from './base-button';
import { cn } from './utils/cn';

const buttonClass = 'bits-text-button-1 rounded-md h-11 px-6 py-2';

export const largeButtonVariants = (
  variant: VariantProps<typeof buttonVariants>['variant']
) => {
  return cn(buttonClass, buttonVariants({ variant }));
};

export interface LargeButtonProps extends ButtonProps {
  loading?: boolean;
}

export const LargeButton = React.forwardRef<
  HTMLButtonElement,
  LargeButtonProps
>(
  (
    {
      asChild,
      children,
      className,
      variant,
      icon: Icon,
      iconProps,
      loading,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonClass, buttonVariants({ variant, className }))}
        ref={ref}
        {...props}
      >
        {!loading && (
          <>
            {Icon ? (
              <Icon
                {...iconProps}
                className={cn('h-4 w-4', iconProps?.className)}
              />
            ) : null}
            {children}
          </>
        )}

        {loading && <SmallSpinner />}
      </Comp>
    );
  }
);

LargeButton.displayName = 'LargeButton';
