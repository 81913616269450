import bitsColors from '@bits/tailwind-config/bits-colors';
import { z } from 'zod';

const fontWeight = z.enum(['normal', 'bold']);

export const assetSchema = z.object({
  name: z.string(),
  url: z.string(),
});

export const customerThemeSchema = z.object({
  // General
  general: z
    .object({
      dividerColor: z.string().default(bitsColors.fog),

      // Logos
      logo: assetSchema.nullish(),
      pageLogo: assetSchema.nullish(),
      pageLogoMaxHeight: z.number().default(40),

      // Focus
      focusColor: z.string().default(bitsColors.ink),

      // Fonts
      fonts: z
        .object({
          regular: assetSchema.nullish(),
          bold: assetSchema.nullish(),
        })
        .default({}),
    })
    .default({}),

  // Page
  page: z
    .object({
      backgroundColor: z.string().default(bitsColors['sand-light']),
    })
    .default({}),

  // Canvas
  canvas: z
    .object({
      backgroundColor: z.string().default(bitsColors.white),
      borderColor: z.string().default(bitsColors.ink),
      borderWidth: z.number().default(0),
      cornerRadius: z.number().default(16),
      shadow: z.boolean().default(true),
    })
    .default({}),

  // Buttons
  buttons: z
    .object({
      primary: z
        .object({
          letterSpacing: z.number().default(0),
          fontSize: z.number().default(14),
          borderWidth: z.number().default(0),
          cornerRadius: z.number().default(6),
          fontWeight: fontWeight.default('bold'),

          shadow: z.boolean().default(false),
          uppercase: z.boolean().default(false),

          normal: z
            .object({
              backgroundColor: z
                .string()
                .default(bitsColors['tangerine-light']),
              borderColor: z.string().default(bitsColors.ink),
              textColor: z.string().default(bitsColors.ink),
            })
            .default({}),
          hover: z
            .object({
              backgroundColor: z.string().default(bitsColors['tangerine']),
              borderColor: z.string().default(bitsColors.ink),
              textColor: z.string().default(bitsColors.ink),
            })
            .default({}),
        })
        .default({}),
    })
    .default({}),

  // Typography
  typography: z
    .object({
      title: z
        .object({
          fontSize: z.number().default(22),
          fontWeight: fontWeight.default('bold'),
          letterSpacing: z.number().default(-0.5),
          textColor: z.string().default(bitsColors.ink),
          uppercase: z.boolean().default(false),
        })
        .default({}),
      paragraph: z
        .object({
          fontSize: z.number().default(16),
          fontWeight: fontWeight.default('normal'),
          letterSpacing: z.number().default(0),
          textColor: z.string().default(bitsColors.ink),
          uppercase: z.boolean().default(false),
        })
        .default({}),
    })
    .default({}),

  // Inputs
  inputs: z
    .object({
      backgroundColor: z.string().default(bitsColors.white),
      borderWidth: z.number().default(1),
      cornerRadius: z.number().default(6),
      shadow: z.boolean().default(false),
      fontSize: z.number().default(16),
      borderColor: z.string().default(bitsColors.fog),
      textColor: z.string().default(bitsColors.ink),

      placeholderColor: z.string().default(bitsColors.ash),

      labelTextColor: z.string().default(bitsColors.ink),
      labelFontSize: z.number().default(14),

      hintAndErrorFontSize: z.number().default(12),
      hintTextColor: z.string().default(bitsColors.shadow),
      errorColor: z.string().default(bitsColors.grapefruit),

      checkboxesAndRadios: z
        .object({
          unchecked: z
            .object({
              backgroundColor: z.string().default(bitsColors.white),
              borderColor: z.string().default(bitsColors.ink),
            })
            .default({}),
          checked: z
            .object({
              backgroundColor: z.string().default(bitsColors.white),
              borderColor: z.string().default(bitsColors.ink),
            })
            .default({}),
          indicatorColor: z.string().default(bitsColors.ink),
        })
        .default({}),
    })
    .default({}),
});

export type CustomerTheme = z.infer<typeof customerThemeSchema>;
export type CustomerThemeInputSchema = z.input<typeof customerThemeSchema>;
