/**
 Validates org numbers from Australia
 */
export const validateAustraliaOrgNumber = (number: string) => {
  const australiaOrgNumberRegex = /^\d{2}\s?\d{3}\s?\d{3}\s?\d{3}$/g;

  const australiaCompanyNumberRegex = /^\d{3}\s?\d{3}\s?\d{3}$/g;

  return (
    australiaOrgNumberRegex.test(number) ||
    australiaCompanyNumberRegex.test(number)
  );
};
