import { z } from 'zod';

import { CustomerTheme, customerThemeSchema } from './customer-theme-schema';

/**
 * Replaces certain css keys with values, so that the theme
 * can be purely css-variable driven without "fulhacks".
 */
const replacements = {
  'canvas-shadow': '0px 4px 8px rgba(0, 0, 0, 0.1)',
  'inputs-shadow': '0px 2px 4px rgba(0, 0, 0, 0.1)',
  'buttons-primary-shadow': '0px 2px 4px rgba(0, 0, 0, 0.1)',

  'typography-title-uppercase': 'uppercase',
  'typography-paragraph-uppercase': 'uppercase',
  'buttons-primary-uppercase': 'uppercase',

  'typography-links-normal-underline': 'underline',
  'typography-links-hover-underline': 'underline',
};

/**
 * Appends pixel values, and replaces booleans for their css counterparts.
 */
const transformValue = (
  key: string,
  value: string | number | boolean
): string => {
  if (typeof value === 'number') {
    return `${value}px`;
  }

  if (
    replacements[key as keyof typeof replacements] &&
    typeof value === 'boolean'
  ) {
    // If the value is truish, return the replacement, otherwise return 'initial'
    return value ? replacements[key as keyof typeof replacements] : 'initial';
  }

  return value as string;
};

const objectToCSSVariables = (schema: CustomerTheme) => {
  const obj = customerThemeSchema.parse(schema);

  const cssVariables = Object.entries(obj).reduce(
    (acc, [key, value]) => {
      // recursively go through the object and create css variables
      // this can be n levels deep
      const createCssVariables = (obj: Record<string, any>, parentKey = '') => {
        Object.entries(obj).forEach(([key, value]) => {
          if (typeof value === 'object') {
            createCssVariables(value, `${parentKey}-${key}`);
          } else {
            acc[`--${parentKey}-${key}`] = transformValue(
              `${parentKey}-${key}`,
              value
            );
          }
        });
      };

      createCssVariables(value, key);

      return acc;
    },
    {} as Record<string, string>
  );

  return cssVariables;
};

/**
 * Takes an object and returns an object with the customer theme and css variables.
 *
 * @example
 * const { customerTheme, cssVariables } = objectToCustomerTheme({
 *  inputs: {
 *   borderWidth: 2,
 *  }
 * });
 */
export const objectToCustomerTheme = (
  schema: z.input<typeof customerThemeSchema>
) => {
  const customerTheme = customerThemeSchema.parse(schema);
  const cssVariables = objectToCSSVariables(customerTheme);

  return {
    customerTheme,
    cssVariables,
  };
};
