import { forwardRef } from 'react';
import { SwitchProps } from '@radix-ui/react-switch';

import { Label, LabelClassVariants } from '../label';
import { Switch } from '../switch';
import { InputErrorAndHint } from './input-error-and-hint';

type FormSwitchProps = {
  label: string;
  error?: string;
  hint?: string;
  value: boolean;
  labelVariant?: LabelClassVariants['variant'];
} & Omit<SwitchProps, 'onChange' | 'value'> & {
    onChange?: (e: {
      target: {
        name?: string;
        value: boolean;
      };
    }) => void;
    onValueChange?: (value: boolean) => void;
  };

export const FormSwitch = forwardRef<HTMLButtonElement, FormSwitchProps>(
  ({ label, error, hint, value, labelVariant, ...props }, ref) => {
    return (
      <div>
        <Label
          className="flex cursor-pointer items-center justify-between"
          variant={labelVariant}
        >
          <p>{label}</p>
          <Switch
            {...props}
            ref={ref}
            onChange={undefined}
            checked={value}
            defaultChecked={value}
            onCheckedChange={(value) => {
              // Make switch work with react-hook-form
              props.onChange?.({
                target: {
                  name: props.name,
                  value,
                },
              });
              props.onValueChange?.(value);
            }}
          />
        </Label>
        <InputErrorAndHint error={error} hint={hint} />
      </div>
    );
  }
);

FormSwitch.displayName = 'FormSwitch';
