import Zod, { z } from 'zod';

import { HttpError } from './http-error';
import { withErrors } from './with-errors';

export { HttpError, withErrors };

export type FetchParams = Omit<RequestInit, 'headers'> & {
  headers?: Record<string, string>;
};

export const fetchAndParse = async (
  reqInfo: RequestInfo | string,
  reqInit?: FetchParams
) => {
  const resp = await fetch(reqInfo, {
    ...reqInit,
    headers: {
      ...reqInit?.headers,
      // Use the provided correlation ID or generate a new one
      'bits-correlation-id':
        reqInit?.headers?.['bits-correlation-id'] || crypto.randomUUID(),
    },
  });

  if (!resp.ok) {
    const text = await resp.text();
    throw new HttpError(
      resp.status,
      resp.statusText,
      text,
      resp.headers.get('bits-correlation-id') || undefined
    );
  }
  const text = await resp.text();
  if (text === '') {
    return null;
  }
  return JSON.parse(text);
};

export function fetcher<
  TSchema extends Zod.ZodType | undefined,
  UValues extends TSchema extends Zod.ZodType ? Zod.infer<TSchema> : undefined,
>(schema?: TSchema) {
  return async (
    input: RequestInfo | string,
    init?: FetchParams
  ): Promise<UValues> => {
    const parsable = schema ?? z.any();
    const data = await fetchAndParse(input, init);
    return parsable.parse(data);
  };
}
