import * as React from 'react';

type SetStateFunc<T> = (prevState?: T) => T;

export const useControlledState = <T>(
  prop?: T | undefined,
  defaultProp?: T | undefined,
  onChange?: (state: T) => void
) => {
  const [uncontrolledProp, setUncontrolledProp] = useUncontrolledState(
    defaultProp,
    onChange
  );
  const isControlled = prop !== undefined;
  const value = isControlled ? prop : uncontrolledProp;
  const handleChange = useCallbackRef(onChange);

  const setValue: React.Dispatch<React.SetStateAction<T | undefined>> =
    React.useCallback(
      (nextValue) => {
        if (isControlled) {
          const setter = nextValue as SetStateFunc<T>;
          const value =
            typeof nextValue === 'function' ? setter(prop) : nextValue;
          if (value !== prop) handleChange(value as T);
        } else {
          setUncontrolledProp(nextValue);
        }
      },
      [isControlled, prop, setUncontrolledProp, handleChange]
    );

  return [value, setValue] as const;
};

export const useUncontrolledState = <T>(
  defaultProp?: T | undefined,
  onChange?: (state: T) => void
) => {
  const uncontrolledState = React.useState<T | undefined>(defaultProp);
  const [value] = uncontrolledState;
  const prevValueRef = React.useRef(value);
  const handleChange = useCallbackRef(onChange);

  React.useEffect(() => {
    if (prevValueRef.current !== value) {
      handleChange(value as T);
      prevValueRef.current = value;
    }
  }, [value, prevValueRef, handleChange]);

  return uncontrolledState;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useCallbackRef = <T extends (...args: any[]) => any>(
  callback: T | undefined
): T => {
  const callbackRef = React.useRef(callback);

  React.useEffect(() => {
    callbackRef.current = callback;
  });

  // https://github.com/facebook/react/issues/19240
  return React.useMemo(
    () => ((...args) => callbackRef.current?.(...args)) as T,
    []
  );
};
