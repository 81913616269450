import { z } from 'zod';

export const customerSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export const createCustomerSchema = z.object({
  name: z.string().min(1),
});

export const createCustomerDocumentPresignedUrlRequestSchema = z.object({
  name: z.string().min(1),
  contentType: z.string().min(1),
  contentLength: z.number().min(1),
});

export const createCustomerDocumentPresignedUrlResponseSchema = z.object({
  url: z.string(),
  documentId: z.string(),
});

export const user = z.object({
  id: z.string(),
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
  createdAt: z.string().pipe(z.coerce.date()),
  updatedAt: z.string().pipe(z.coerce.date()),
});

export const listCustomerUsersSchema = z.object({
  users: z.array(user),
});

export type ListCustomerUsers = z.infer<typeof listCustomerUsersSchema>;

export type BitsUser = z.infer<typeof user>;
