import { ISO3166Alpha2, ISO3166Alpha2OrUnknown } from '../iso-3166';

const orgIdPlaceholders: Partial<
  Record<ISO3166Alpha2, { format: string; example: string }>
> = {
  IE: {
    format: 'NNNNNN',
    example: '123456',
  },
  SE: {
    format: 'NNNNNN-NNNN',
    example: '460503-6195',
  },
  GB: {
    format: 'AANNNNNN',
    example: 'AZ123456',
  },
  NO: {
    format: 'NNN NNN NNN',
    example: '123 456 789',
  },
  FI: {
    format: 'NNNNNNN-N',
    example: '3056663-8',
  },
  DK: {
    format: 'NNNNNNNN',
    example: '12345678',
  },
} as const;

export const getOrgNumberFormat = (
  market?: ISO3166Alpha2
): { format: string; example: string } | undefined => {
  return orgIdPlaceholders[market || 'SE'];
};

export const formatOrgNumber = (
  value?: string,
  market?: ISO3166Alpha2OrUnknown
) => {
  if (!value) {
    return '';
  }

  // Sweden
  if (market === 'SE') {
    return (
      value
        // Remove all non-digits
        .replace(/\D/g, '')
        // Format for Sweden
        .replace(/(\d{6})(\d{4})/, '$1-$2')
    );
  }

  // Finland
  if (market === 'FI') {
    return (
      value
        // Remove all non-digits
        .replace(/\D/g, '')
        // Format for Finland
        .replace(/(\d{7})(\d{1})/, '$1-$2')
    );
  }

  // Norway
  if (market === 'NO') {
    return (
      value
        // Remove all non-digits and letters
        .replace(/[^0-9a-zA-Z]/g, '')
        // Format for Norway
        .replace(/(\d{3})(\d{3})(\d{3})(\s{3})?/, '$1 $2 $3 $4')
        .trim()
    );
  }

  return value;
};
