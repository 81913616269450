import { useMemo } from 'react';

export const useUuid = (id?: string | null) => {
  const uuid = useMemo(
    () => id ?? Math.random().toString(36).substring(7),
    [id]
  );

  return uuid;
};
