import React from 'react';
import { cva, VariantProps } from 'class-variance-authority';

import { Icon } from './icon';

export const buttonVariants = cva(
  'ring-offset-background focus-visible:ring-ring inline-flex items-center justify-center gap-2 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-20',
  {
    variants: {
      variant: {
        default: `bg-primary hover:bg-primary/60 text-primary-foreground
                  dark:bg-background dark:text-foreground dark:hover:bg-background/60`,
        secondary: `bg-secondary hover:bg-secondary/60 text-secondary-foreground`,
        outline:
          'border-input bg-background hover:text-accent-foreground border hover:opacity-50',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        destructive:
          'bg-destructive hover:bg-destructive/60 text-destructive-foreground',
        'destructive-outline':
          'border-destructive text-destructive bg-background border hover:opacity-50',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  icon?: Icon;
  iconProps?: Omit<React.SVGProps<SVGSVGElement>, 'ref'>;
  asChild?: boolean;
}
