import { ReactNode } from 'react';
import { X } from '@phosphor-icons/react';

import { cn } from '../utils/cn';
import { FilterDropdown, useFilterContext } from './advanced-filters';

interface ActiveFilterButtonProps {
  label: string;
  values: ReactNode[];
  section: string;
  onClear: () => void;
}
export const ActiveFilterButton = ({
  label,
  values,
  onClear,
  section,
}: ActiveFilterButtonProps) => {
  const filterContext = useFilterContext();

  return (
    <div className="border-fog relative flex h-full rounded-[4px] border bg-white">
      <FilterDropdown {...filterContext} section={section} backButton={false}>
        <button
          className={cn(
            'bits-text-button-2 hover:bg-ink/5 flex items-center h-full text-left cursor-pointer gap-2 py-1 px-2.5',
            'transition-colors outline-offset-2 focus-within:outline-2 focus-within:outline-ink/25'
          )}
        >
          <span className="text-ash">{label}:</span>{' '}
          <div className="flex flex-wrap gap-x-2">
            {values.map((p, i) => (
              <span key={i}>{p}</span>
            ))}
          </div>
        </button>
      </FilterDropdown>
      <button
        className="hover:bg-ink/5 focus-within:outline-ink/25 justify-center p-1 outline-offset-2 focus-within:outline-2"
        onClick={onClear}
        aria-label={'Clear filter: ' + label}
      >
        <X className="size-4" aria-hidden />
      </button>
    </div>
  );
};
