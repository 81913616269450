import { getOrgNumberFormat } from '@bits/i18n';

import {
  ThemedCountrySpecificStringInput,
  ThemedCountrySpecificStringInputProps,
} from './themed-country-specific-string-input';

export const ThemedOrganizationNumberInput = ({
  defaultCountryCode = 'SE',
  language = 'en',
  ...props
}: Omit<ThemedCountrySpecificStringInputProps, 'getStringFormat'>) => {
  return (
    <ThemedCountrySpecificStringInput
      {...props}
      language={language}
      defaultCountryCode={defaultCountryCode}
      getStringFormat={getOrgNumberFormat}
    />
  );
};
