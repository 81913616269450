import {
  availableFeatureFlags,
  useFeatureFlag,
} from './feature-flags-provider';

export const useCanUseRemoteRegistryDocuments = () =>
  useFeatureFlag(
    availableFeatureFlags.CAN_USE_REMOTE_REGISTRY_DOCUMENTS,
    false
  );
