'use client';

import * as React from 'react';
import { Circle } from '@phosphor-icons/react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { cn } from '../utils/cn';
import { useUuid } from '../utils/use-uuid';

export interface ThemedRadioGroupProps
  extends React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root> {}

export const ThemedRadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  ThemedRadioGroupProps
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn('grid gap-3', className)}
      {...props}
      ref={ref}
    />
  );
});
ThemedRadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

export interface ThemedRadioGroupItemProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>,
    'label'
  > {
  label: string;
  error?: string;
}

export const ThemedRadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  ThemedRadioGroupItemProps
>(({ className, label, error, ...props }, ref) => {
  const uuid = useUuid();

  return (
    <label
      className={cn(
        'flex gap-2 cursor-pointer',
        props.disabled && 'cursor-not-allowed opacity-50'
      )}
    >
      <RadioGroupPrimitive.Item
        ref={ref}
        className={cn(
          'relative [top:calc(var(--typography-paragraph-fontSize)-16px)] aspect-square shrink-0 [width:calc(20px+var(--inputs-borderWidth))] [height:calc(20px+var(--inputs-borderWidth))] rounded-full',
          'border-theme-inputs-labelTextColor text-theme-inputs-labelTextColor [border-width:max(var(--inputs-borderWidth),1px)] [box-shadow:var(--inputs-shadow)]',
          'outline-none focus-visible:outline-2 focus-visible:outline-theme-general-focusColor',
          'data-[state=unchecked]:bg-theme-inputs-checkboxesAndRadios-unchecked-backgroundColor data-[state=checked]:bg-theme-inputs-checkboxesAndRadios-checked-backgroundColor',
          'data-[state=unchecked]:border-theme-inputs-checkboxesAndRadios-unchecked-borderColor data-[state=checked]:border-theme-inputs-checkboxesAndRadios-checked-borderColor',
          error && 'border-theme-inputs-errorColor',
          className
        )}
        aria-labelledby={uuid}
        {...props}
      >
        <RadioGroupPrimitive.Indicator className="animate-in zoom-in-50 relative flex items-center justify-center">
          <Circle
            weight="fill"
            className="fill-theme-inputs-checkboxesAndRadios-indicatorColor size-[11px]"
          />
        </RadioGroupPrimitive.Indicator>
      </RadioGroupPrimitive.Item>
      <p
        aria-hidden
        className={cn(
          'text-theme-inputs-labelTextColor text-theme-typography-paragraph-fontSize',
          error && 'text-theme-inputs-errorColor'
        )}
        id={uuid}
      >
        {label}
      </p>
    </label>
  );
});
ThemedRadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;
