import { memo } from 'react';
import { setFeatureFlagContext } from '@bits/feature-flags';

import { getBitsEnvironment } from '@/lib/get-bits-environment';
import { useCurrentUser } from '@/lib/hooks/use-current-user';

export const FeatureFlagContextSetters = memo(() => {
  const { isBitsEmployee, email } = useCurrentUser();

  setFeatureFlagContext({ environment: getBitsEnvironment() });
  setFeatureFlagContext({ email });
  setFeatureFlagContext({ isBitsEmployee });

  return null;
});

FeatureFlagContextSetters.displayName = 'FeatureFlagContextSetters';
