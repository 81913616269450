import { memo, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

export const PageNavigationIndicator = memo(() => {
  const router = useRouter();
  const bar = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let animationStarted = false;
    let navigationFinished = false;

    router.events.on('routeChangeStart', () => {
      navigationFinished = false;
      setTimeout(() => {
        // Don't show the loading indicator if the page is already loaded
        if (bar.current && !navigationFinished) {
          animationStarted = true;
          bar.current.style.transition = 'none';
          bar.current.style.transform = 'scaleX(0)';
          bar.current.style.opacity = '0';

          bar.current.offsetWidth; // trigger layout

          bar.current.style.transition = 'all 2s ease-out, opacity .1s linear';
          bar.current.style.opacity = '1';
          bar.current.style.transform = 'scaleX(.33)';

          // eslint-disable-next-line @typescript-eslint/no-empty-function
          bar.current.ontransitionend = () => {};
        }
        // Only show the loading indicator if the page takes longer than 150ms to load
      }, 150);
    });
    // Reset on error
    router.events.on('routeChangeError', () => {
      navigationFinished = true;
    });
    router.events.on('routeChangeComplete', () => {
      navigationFinished = true;
      if (bar.current && animationStarted) {
        bar.current.style.transition =
          'transform .4s ease-out, opacity .2s linear .2s';
        bar.current.style.transform = 'scaleX(1)';

        bar.current.ontransitionend = () => {
          if (bar.current) bar.current.style.opacity = '0';
        };
      }
    });
  }, [router]);

  return (
    <div
      ref={bar}
      className="bg-kiwi fixed left-0 top-0 h-1 w-full origin-top-left opacity-0 shadow-lg"
    />
  );
});

PageNavigationIndicator.displayName = 'PageNavigationIndicator';
