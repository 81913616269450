import { useState } from 'react';
import { useIsomorphicLayoutEffect } from 'framer-motion';

export const useIsIOS = () => {
  const [isIOS, setIsIOS] = useState(false);

  useIsomorphicLayoutEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    setIsIOS(
      /ipad|iphone|ipod/.test(window.navigator.userAgent.toLowerCase()) ||
        // iPad on iOS 13 detection
        (/macintosh/.test(window.navigator.userAgent.toLowerCase()) &&
          window.navigator.maxTouchPoints > 1)
    );
  }, []);

  return isIOS;
};

export const useIsAndroid = () => {
  const [isAndroid, setIsAndroid] = useState(false);

  useIsomorphicLayoutEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    setIsAndroid(/android/.test(window.navigator.userAgent.toLowerCase()));
  }, []);

  return isAndroid;
};

export const useIsHandheld = () => {
  const [isHandheld, setIsHandheld] = useState(false);

  useIsomorphicLayoutEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    setIsHandheld(
      /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        window.navigator.userAgent.toLowerCase()
      ) ||
        // iPad on iOS 13 detection
        (/macintosh/.test(window.navigator.userAgent.toLowerCase()) &&
          window.navigator.maxTouchPoints > 1)
    );
  }, []);

  return isHandheld;
};
