import { ISO3166Alpha2 } from '../iso-3166';
import { validateAustraliaOrgNumber } from './validate-australia-org-number';
import { validateAustriaOrgNumber } from './validate-austria-org-number';
import { validateBelgiumOrgNumber } from './validate-belgium-org-number';
import { validateCanadaOrgNumber } from './validate-canada-org-number';
import { validateChinaOrgNumber } from './validate-china-org-number';
import { validateCyprusOrgNumber } from './validate-cyprus-org-number';
import { validateDenmarkOrgNumber } from './validate-denmark-org-number';
import { validateEstoniaOrgNumber } from './validate-estonia-org-number';
import { validateFinlandOrgNumber } from './validate-finland-org-number';
import { validateFranceOrgNumber } from './validate-france-org-number';
import { validateGermanyOrgNumber } from './validate-germany-org-number';
import { validateIndiaOrgNumber } from './validate-india-org-number';
import { validateIrelandOrgNumber } from './validate-ireland-org-number';
import { validateItalyOrgNumber } from './validate-italy-org-number';
import { validateNorwayOrgNumber } from './validate-norway-org-number';
import { validateSingaporeOrgNumber } from './validate-singapore-org-number';
import { validateSpainOrgNumber } from './validate-spain-org-number';
import { validateSwedenOrgNumber } from './validate-sweden-org-number';
import { validateSwitzerlandOrgNumber } from './validate-switzerland-org-number';
import { validateUnitedArabEmiratesOrgNumber } from './validate-united-arab-emirates-org-number';
import { validateUnitedKingdomOrgNumber } from './validate-united-kingdom-org-number';

type Validator = (number: string) => boolean;

const validators: Partial<Record<ISO3166Alpha2, Validator>> = {
  AE: validateUnitedArabEmiratesOrgNumber,
  AT: validateAustriaOrgNumber,
  AU: validateAustraliaOrgNumber,
  BE: validateBelgiumOrgNumber,
  CA: validateCanadaOrgNumber,
  CH: validateSwitzerlandOrgNumber,
  CN: validateChinaOrgNumber,
  CY: validateCyprusOrgNumber,
  DE: validateGermanyOrgNumber,
  DK: validateDenmarkOrgNumber,
  EE: validateEstoniaOrgNumber,
  ES: validateSpainOrgNumber,
  FR: validateFranceOrgNumber,
  FI: validateFinlandOrgNumber,
  GB: validateUnitedKingdomOrgNumber,
  IE: validateIrelandOrgNumber,
  IN: validateIndiaOrgNumber,
  IT: validateItalyOrgNumber,
  SG: validateSingaporeOrgNumber,
  NO: validateNorwayOrgNumber,
  SE: validateSwedenOrgNumber,
} as const;

/**
 * Validates organization number based on issuing country
 *
 * @param number
 * @param issuingCountry
 * @returns Boolean
 */
export const validateOrgNumber = (
  number: string,
  issuingCountry?: ISO3166Alpha2
) => {
  if (!number || !issuingCountry) {
    return false;
  }

  const validator = validators[issuingCountry];

  if (validator) {
    return validator(number);
  }

  return true;
};
