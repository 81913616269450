'use client';

import * as React from 'react';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import { DayPicker } from 'react-day-picker';

import { buttonVariants } from './base-button';
import { cn } from './utils/cn';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ISOWeek = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      ISOWeek={ISOWeek}
      showOutsideDays={showOutsideDays}
      className={cn('p-6', className)}
      classNames={{
        vhidden: 'hidden',
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-md font-medium flex gap-2 items-center',
        caption_dropdowns: 'flex bits-text-subtitle-1 gap-2',
        caption_between: 'bg-orange-500',
        dropdown_month: 'relative pr-3',
        dropdown_year: 'relative pr-3',
        dropdown: 'absolute w-full h-full opacity-0',
        nav: 'space-x-1 flex items-center',
        nav_button:
          'rounded-full h-6 w-6 bg-fog hover:opacity-100 flex items-center justify-center',
        nav_button_previous:
          'absolute left-1 disabled:opacity-50 disabled:cursor-not-allowed',
        nav_button_next:
          'absolute right-1 disabled:opacity-50 disabled:cursor-not-allowed',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex w-full justify-between',
        head_cell: 'text-shadow rounded-md w-8 bits-text-caption',
        row: 'flex w-full mt-1 gap-1',
        cell: cn(
          'relative p-0 text-center text-ink bits-text-body-1 focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-accent [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected].day-range-end)]:rounded-r-md',
          props.mode === 'range'
            ? '[&:has(>.day-range-end)]:rounded-r-full [&:has(>.day-range-start)]:rounded-l-full first:[&:has([aria-selected])]:rounded-l-full last:[&:has([aria-selected])]:rounded-r-full'
            : '[&:has([aria-selected])]:rounded-full'
        ),
        day: cn(
          buttonVariants({ variant: 'ghost' }),
          'h-9 w-9 p-0 font-normal aria-selected:opacity-100 rounded-full'
        ),
        day_range_start: 'day-range-start',
        day_range_end: 'day-range-end',
        day_selected:
          'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
        day_today: 'bg-kiwi-light text-ink',
        day_outside:
          'day-outside text-muted-foreground opacity-50  aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
        day_disabled: 'text-muted-foreground opacity-50',
        day_range_middle:
          'aria-selected:bg-accent aria-selected:text-accent-foreground',
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        IconLeft: () => <CaretLeft weight="bold" className="size-3" />,
        IconRight: () => <CaretRight weight="bold" className="size-3" />,
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
