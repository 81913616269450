import React from 'react';

import { cn } from './utils/cn';

export const SmallSpinner = ({ className }: { className?: string }) => {
  return (
    <div className={cn('flex items-center justify-center', className)}>
      <div className="w-4 h-4 border-2 border-l-transparent rounded-full animate-spin border-current"></div>
    </div>
  );
};
